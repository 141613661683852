var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Khonsole } from 'app/khonsole';
import { AfterViewInit, ChangeDetectorRef, ElementRef, OnDestroy, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import * as d3 from 'd3';
import * as _ from 'lodash';
import introJs from 'intro.js';
import { GraphConfig } from './../../../model/graph-config.model';
import { DatasetDescription } from 'app/model/dataset-description.model';
import { DataService } from 'app/service/data.service';
import { ComputeWorkerUtil } from 'app/service/compute.worker.util';
import { WorkspaceComponent } from '../workspace.component';
import { SelectionModifiers } from 'app/component/visualization/visualization.abstract.scatter.component';
import { ChartScene } from 'app/component/workspace/chart/chart.scene';
import { OncoData } from 'app/oncoData';
import { SurvivalWidgetComponent } from './survival-widget.component';
import { CopynumberWidgetComponent } from './copynumber-widget.component';
import { DiffexpWidgetComponent } from './diffexp-widget.component';
import { CohortsWidgetComponent } from './cohorts-widget.component';
import { CommonSidePanelModel } from './commonSidePanelModel';
import { DatasetTour } from './../../../model/dataset-table-info.model';
export class CommonSidePanelComponent {
    constructor(cd, dataService) {
        this.cd = cd;
        this.dataService = dataService;
        this.version = "123";
        this.navItems = [];
        this.hide = new EventEmitter();
        this.wutil = new ComputeWorkerUtil(); // Not for remote cpu calls, just for data access utility functions.
        this.oncoBandsData = [];
        this.autoUpdate = true;
        this.updateSurvival = _.debounce(this.update, 600);
        // These are things in config which trigger reloading or reoptimizing data for copynumber widget.
        // Everything else in config can be left alone for these purposes.
        this.lastCopynumberProcessedCohortName = null;
        this.tablesThatCouldBeUsedForCopynumber = [];
        this._legends = [];
        OncoData.instance.currentCommonSidePanel = this;
        CommonSidePanelComponent.instance = this;
        this.commonSidePanelModel = new CommonSidePanelModel();
        this.commonSidePanelModel.width = 268; //was 262. full sidebar width is 275.
        CommonSidePanelComponent.setSurvival.subscribe(this.onSetLegends.bind(this));
    }
    getCnaDataForGene(geneName) {
        if (this.commonSidePanelModel.cnaData) {
            let r = this.commonSidePanelModel.cnaData.find((v) => v.m == geneName);
            if (r) {
                return r;
            }
        }
        return null;
    }
    get config() {
        return this._config;
    }
    hasMenuItems() {
        if (OncoData.instance.dataLoadedAction &&
            OncoData.instance.dataLoadedAction.dataset) {
            let name = OncoData.instance.dataLoadedAction.dataset;
            return name == "tinytest" || name.includes("eningiomaumap");
        }
        else {
            return false;
        }
    }
    set config(configValue) {
        let self = this;
        if (configValue === null) {
            return;
        }
        if (OncoData.instance.dataLoadedAction &&
            OncoData.instance.dataLoadedAction.dataset) {
            let savedNavItems = OncoData.instance.dataLoadedAction.datasetTableInfo.navItems;
            if (savedNavItems && savedNavItems.length > 0) {
                this.navItems = savedNavItems;
            }
            else {
                this.navItems = [];
            }
        }
        if (this._config == null || this._config.database != configValue.database) {
            this.commonSidePanelModel.definedCohorts = []; // was null
            this.commonSidePanelModel.patientMap = null;
            this.commonSidePanelModel.sampleMap = null;
            this.commonSidePanelModel.patientData = null;
            this.commonSidePanelModel.cnaData = null;
            this.commonSidePanelModel.cnaSampleMapData = null;
            this.commonSidePanelModel.lastCopynumberProcessedDatabase = null;
            this.lastCopynumberProcessedCohortName = null;
            this.commonSidePanelModel.lastCopynumberProcessedMarkerFilterAsString =
                null;
            this.visuallyClearWidgets();
        }
        // TEMPNOTE: this seems unneeded.
        // if (this._config == null ||
        //   this._config.visualization != configValue.visualization)
        // {
        this.lastCopynumberProcessedCohortName = null;
        // this.lastCopynumberProcessedMarkerFilterAsString = null;
        // }
        if (configValue.cohortName != this.lastCopynumberProcessedCohortName) {
            this.lastCopynumberProcessedCohortName = null;
        }
        if (configValue.markerFilter.join(" ") !=
            this.commonSidePanelModel.lastCopynumberProcessedMarkerFilterAsString) {
            this.commonSidePanelModel.lastCopynumberProcessedMarkerFilterAsString =
                null;
        }
        this._config = configValue;
        this.commonSidePanelModel.graphConfig = this._config;
        if (this.newSurvivalWidget) {
            this.newSurvivalWidget.processConfigChange(configValue);
        }
        if (this.newCopynumberWidget) {
            this.newCopynumberWidget.processConfigChange(configValue);
        }
        if (this.newDiffexpWidget) {
            this.newDiffexpWidget.processConfigChange(configValue);
        }
        if (this.newCohortsWidget) {
            this.newCohortsWidget.processConfigChange(configValue);
        }
        if (!this.commonSidePanelModel.definedCohorts) {
            this.dataService
                .getCustomCohorts(this.config.database)
                .then((retrievedCustomCohorts) => {
                this.commonSidePanelModel.definedCohorts = retrievedCustomCohorts;
                self.populateCohortsHtmlTable(self.commonSidePanelModel.definedCohorts);
            });
        }
        this.processConfigChangeAfterGenomeLoaded();
    }
    getSavedPointsWrappers(database) {
        return this.dataService.getSavedPointsWrappers(database);
    }
    putSavedPointsWrapper(database, wrapper) {
        return this.dataService.putSavedPointsWrapper(this.config.database, wrapper);
    }
    shouldUseDiffExp() {
        // should never use the old naive differential expression widget, until pyhscript replacement is ready.
        // also, see if maybe it is a MEMORY HOG that never releases memory when switching or reloading datasets.
        return false;
        let isMening = OncoData.instance.dataLoadedAction.dataset.includes("meningiomaumap");
        let isBrain = OncoData.instance.dataLoadedAction.dataset.includes("brainumap");
        if (isMening || isBrain) {
            return false;
        }
        return this.datasetDescription.hasMatrixFields;
    }
    /**
     * Get the color of the "All" cohort (all samples)
     */
    getColorOfAll() {
        return this.commonSidePanelModel.cohortColors[0];
    }
    /**
     *
     * @description Calculate the color to be assigned to a cohort. Based on colors already assigned to other cohorts.
     * @param preferredColor The preferred color for the cohort being created. Will be satisfied as best as possible.
     * @returns The color that should be assigned to the cohort
     */
    calculateAssignedCohortColort({ cohort = undefined, preferredColor = undefined }) {
        const COLOR_DISTANCE_THRESHOLD = 100;
        const presetColors = this.commonSidePanelModel.cohortColors;
        if (cohort && (cohort.n.toLowerCase() == "all" || cohort.n.toLowerCase() == "all patients and samples")) {
            // It is the special "all" cohort, use first color.
            return presetColors[0];
        }
        // get the colors of the already assigned cohorts
        const assignedColors = this.commonSidePanelModel.definedCohorts.map((cohort, i) => {
            if (cohort.assignedColor) {
                return cohort.assignedColor;
            }
            // for existing cohorts, every one has an assigned color, but for the "All" cohort and for legacy cohorts, the color is undefined.
            // we can grab the color directly from the UI
            const cohortsTable = document.getElementById("savedCohortsTable");
            if (cohortsTable == null) {
                return undefined;
            }
            // get the i-th row of the table
            const tr_i = Array.from(cohortsTable.getElementsByTagName("tr"))[i];
            // get the first cell of the row
            const firstTd = Array.from(tr_i.getElementsByTagName("td"))[0];
            return firstTd.getAttribute("bgcolor");
        });
        // find the first color in the preset list that is not already assigned
        let nextAvailablePreset = presetColors.find((color) => !assignedColors.includes(color));
        if (nextAvailablePreset == undefined) {
            // if all colors are already assigned, start reusing colors
            const numTimesPresetColorsUsed = new Map();
            for (const color of assignedColors.filter(c => presetColors.includes(c))) {
                if (color == undefined) {
                    continue;
                }
                const count = numTimesPresetColorsUsed.get(color) || 0;
                numTimesPresetColorsUsed.set(color, count + 1);
            }
            // use the color that has been used the least
            // sort the colors by the number of times they have been used
            const sortedColors = presetColors.sort((a, b) => {
                const aCount = numTimesPresetColorsUsed.get(a) || 0;
                const bCount = numTimesPresetColorsUsed.get(b) || 0;
                return aCount - bCount;
            });
            // return the first color that has been used the least
            nextAvailablePreset = sortedColors[0];
        }
        // if only null or undefined values were passed, the best we can do is return the first available color from the preset list
        if (!cohort && !preferredColor) {
            return nextAvailablePreset;
        }
        preferredColor = preferredColor ? preferredColor : cohort.preferredColor;
        if (preferredColor == null) {
            // There is no preferred color. Return the first available color from the preset list.
            return nextAvailablePreset;
        }
        for (const color of assignedColors) {
            if (color == undefined) {
                continue;
            }
            // if the preferred color is too close to an already assigned color, then we can't use it. fallback to the next available color in the preset list.
            const distance = this.calculateColorEuclideanDistance(color, preferredColor);
            if (distance < COLOR_DISTANCE_THRESHOLD) {
                // Return the first available color from the preset list.
                return nextAvailablePreset;
            }
        }
        // If we get here, then the preferred color is not too close to any of the already assigned colors.
        return preferredColor;
    }
    /**
     *
     * @param c1 The first color to compare (can be any valid CSS color - rgb, hex, named, etc.)
     * @param c2 The second color to compare (can be any valid CSS color - rgb, hex, named, etc.)
     * @returns The Euclidean distance between the two colors
     */
    calculateColorEuclideanDistance(c1, c2) {
        // Create a temporary element to apply the color
        var tempElement = document.createElement("div");
        tempElement.style.color = c1;
        // Append the element to the body (required for some browsers)
        document.body.appendChild(tempElement);
        // Get the computed colors
        const computedColor1 = window.getComputedStyle(tempElement).color;
        tempElement.style.color = c2;
        const computedColor2 = window.getComputedStyle(tempElement).color;
        // Remove the temporary element
        document.body.removeChild(tempElement);
        // Extract RGB values from the computed colors
        let rgbs = [computedColor1, computedColor2].map((color, i) => {
            var match = color.match(/\d+/g);
            if (match && match.length === 3) {
                var rgbValues = match.map(function (value) {
                    return parseInt(value, 10);
                });
                return rgbValues;
            }
            else {
                Khonsole.error("Unable to extract RGB values for color: " + [c1, c2][i]);
                return null;
            }
        });
        // Calculate the Euclidean distance between the two colors
        const [r1, g1, b1] = rgbs[0];
        const [r2, g2, b2] = rgbs[1];
        return Math.sqrt(Math.pow(r1 - r2, 2) + Math.pow(g1 - g2, 2) + Math.pow(b1 - b2, 2));
    }
    getColorOfSavedCohortByName(name) {
        if (name == "All") {
            // It is All Patients and Samples, use first color.
            return this.commonSidePanelModel.cohortColors[0];
        }
        const cohort = this.commonSidePanelModel.definedCohorts.find((dc) => dc.n == name);
        const pos = this.commonSidePanelModel.definedCohorts.findIndex(c => c.n == name);
        return cohort.assignedColor || this.commonSidePanelModel.cohortColors[pos];
    }
    setColorForSelectedCohort(color) {
        this.commonSidePanelModel.selectionAssignedColor = color;
        document
            .getElementById("colorCellForSelectedCohort")
            .setAttribute("bgcolor", color);
    }
    setAddCohortBtnVisibility(show) {
        document
            .getElementById("cellForAddingCohort")
            .setAttribute("style", show ? "display:block;" : "display:none;");
    }
    // This method used by "*" icon button in timeline, and in spreadhseet.
    createCohortFromPids(pids) {
        let n = pids.length;
        if (n == 0) {
            window.alert("There are no patients selected. Click on the patient IDs in the left column.");
            return;
        }
        var cohortName = prompt(`Please enter a name for the new cohort of ${n} patients.`, "");
        if (cohortName != null && cohortName.trim() != "") {
            let newCohort = this.createCohortFromPatientSelectionIds({
                selectionIds: pids,
            });
            newCohort.n = cohortName;
            WorkspaceComponent.instance.addCohort({
                database: this.config.database,
                cohort: newCohort,
            });
            window.setTimeout(() => {
                this.drawWidgets();
            }, 350);
        }
    }
    /**
     *
     * @param selectionModifiers The modifiers to apply to the selection. If null, no modifiers are applied.
     * @param patientIds The newly selected patient IDs.
     * @param ignorePatientIdCase If true, ignore case when comparing patient IDs. Default is false.
     * @returns The list of patient IDs to be selected, after applying the modifiers.
     */
    processModifiers(selectionModifiers, patientIds, ignorePatientIdCase = false) {
        if (selectionModifiers == null) {
            return patientIds;
        }
        let extendModifier = false;
        let inverseModifier = false;
        if (selectionModifiers) {
            if (selectionModifiers.extend) {
                extendModifier = selectionModifiers.extend;
            }
            if (selectionModifiers.inverse) {
                inverseModifier = selectionModifiers.inverse;
            }
        }
        let selectionIds;
        // Process modifiers, if any.
        if (extendModifier || inverseModifier) {
            let oldSelectionIds = this.commonSidePanelModel.selectionIds;
            if (extendModifier) {
                let allIds = oldSelectionIds.concat(patientIds);
                selectionIds = Array.from(new Set(allIds));
            }
            else {
                if (ignorePatientIdCase) {
                    // Deselect. Take original list and remove all matches in the new list, ignoring case.
                    selectionIds = oldSelectionIds.filter((n) => !patientIds.some((p) => p.toLowerCase() === n.toLowerCase()));
                }
                else {
                    // Deselect. Take original list and remove all matches in the new list.
                    selectionIds = oldSelectionIds.filter((n) => !patientIds.includes(n));
                }
            }
        }
        else {
            selectionIds = patientIds;
        }
        return selectionIds;
    }
    setSelectionPatientIds({ patientIds, existingCohort, selectionModifiers, graphConfig, ignorePatientIdCase = false, preferredCohortColor = null, }) {
        if (existingCohort == "Legend" || existingCohort == "Cohort") {
            Khonsole.log(`${existingCohort} is calling setSelectionPatientIds`);
        }
        else {
            if (graphConfig) {
                Khonsole.log(`Graph ${graphConfig.graph} is calling setSelectionPatientIds`);
            }
            else {
                Khonsole.log(`Graph is null while calling setSelectionPatientIds`);
            }
        }
        if (patientIds == null) {
            window.alert("Expected list of patient IDs, but it was null.");
            return;
        }
        // calculate the selection ids based on the modifiers
        this.commonSidePanelModel.selectionIds = this.processModifiers(selectionModifiers, patientIds, ignorePatientIdCase);
        const noModifiers = selectionModifiers == null || (!selectionModifiers.extend && !selectionModifiers.inverse);
        if ((patientIds.length == 0 ||
            (existingCohort && existingCohort != "Legend")) &&
            noModifiers) {
            if (existingCohort == "Cohort") {
                // Do nothing, this is a followup from selectioncontroller.
                Khonsole.log(`Cohort clicked, are A & B both selected?`);
            }
            else {
                // at this point we know that existingCohort is a proper Cohort object
                let displayName = "All Patients + Samples";
                if (patientIds.length > 0) {
                    displayName = existingCohort.n;
                    this.commonSidePanelModel.lastSelectedDefinedCohort = existingCohort;
                }
                else {
                    this.commonSidePanelModel.lastSelectedDefinedCohort = null;
                    existingCohort = this.commonSidePanelModel.definedCohorts[0]; // Set back to All, if we are deselecting.
                }
                this.newCohortsWidget.selectedCohortText.nativeElement.value = `${displayName} (${existingCohort.pids.length})`;
                const cohortName = existingCohort.n;
                this.commonSidePanelModel.selectionPreferredColor = preferredCohortColor;
                this.setColorForSelectedCohort(this.getColorOfSavedCohortByName(cohortName));
                this.setAddCohortBtnVisibility(false);
                if (OncoData.instance.currentSelectionController) {
                    OncoData.instance.currentSelectionController.setSelectionViaCohortDirect(existingCohort);
                }
            }
        }
        else {
            // We are creating a new cohort from the selection or legend
            if (existingCohort != null) {
                // Just for Legend case, not Selection.
                let source = existingCohort == "Legend" ? "Legend" : "Selection";
                Khonsole.warn(`source = ${source}.`);
                if (OncoData.instance.currentSelectionController) {
                    let aCohort = this.createCohortFromPatientSelectionIds({
                        selectionIds: this.commonSidePanelModel.selectionIds,
                        ignoreIdCase: ignorePatientIdCase,
                        preferredColor: preferredCohortColor,
                    });
                    OncoData.instance.currentSelectionController.setSelectionViaCohortViaSource(aCohort, source);
                }
                else {
                    Khonsole.warn("Trying to create a cohort from Legend/Selection, but no SelectionController is active.");
                }
            }
            this.commonSidePanelModel.lastSelectedDefinedCohort = null;
            let d = `(${this.commonSidePanelModel.selectionIds.length}@${Date()
                .toString()
                .slice(16, 24)})`;
            this.newCohortsWidget.selectedCohortText.nativeElement.value =
                "Selection " + d;
            let colorToAssignToCohort;
            if (existingCohort == "Legend" || existingCohort == "Cohort") {
                colorToAssignToCohort = this.calculateAssignedCohortColort({ preferredColor: preferredCohortColor });
            }
            else {
                colorToAssignToCohort = this.calculateAssignedCohortColort({ cohort: existingCohort });
            }
            this.commonSidePanelModel.selectionPreferredColor = preferredCohortColor;
            this.setColorForSelectedCohort(colorToAssignToCohort);
            this.setAddCohortBtnVisibility(true);
        }
    }
    handleSavedCohortClick(index) {
        if (index <= this.commonSidePanelModel.definedCohorts.length) {
            // svg._groups[0][0].getElementsByClassName('km-curve')[0]
            let mouseEvent = event;
            let c = this.commonSidePanelModel.definedCohorts[index];
            let selectionModifiers = new SelectionModifiers();
            selectionModifiers.extend = mouseEvent.shiftKey;
            selectionModifiers.inverse = mouseEvent.altKey;
            window.setTimeout(() => {
                Khonsole.warn("null graphConfig in CommonSidePanel.handleSavedCohortClick");
                this.setSelectionPatientIds({
                    patientIds: c.pids,
                    existingCohort: c,
                    selectionModifiers,
                    graphConfig: null,
                });
            }, 20);
        }
        else {
            alert(`Could not find defined cohort with index of ${index}.`);
        }
    }
    populateCohortsHtmlTable(cohorts) {
        let tableAsAny = document.getElementById("savedCohortsTable");
        let MyTable = tableAsAny;
        let innerTBody = "";
        for (let i = 0; i < cohorts.length; i++) {
            let nameDescription = cohorts[i].n;
            if (nameDescription != "All Patients") {
                nameDescription = `${nameDescription} (${cohorts[i].pids.length})`;
            }
            // default to a preset color if one isn't set. This happens when there were cohorts made before the smarter color system
            const color = cohorts[i].assignedColor || this.commonSidePanelModel.cohortColors[i];
            //
            let deleteCellContents = "";
            if (i > 0) {
                // Do not offer option to "delete" the All Patients cohort.
                deleteCellContents = `
        <mat-icon
          matTooltip="Compare this cohort to others"
          class="material-icons common-side-cohort-list-delete"
          style="font-size: 16px"
          title="Compare this cohort to others"
          onclick="window.reachableOncoData.currentCommonSidePanel.handleStatsCohortClick(${i});"
          role="img"
          aria-hidden="true"
          >bar_chart</mat-icon
        >
        <mat-icon
          matTooltip="Add this cohort to the selection"
          class="material-icons common-side-cohort-list-delete"
          style="font-size: 16px"
          onclick="window.reachableOncoData.currentCommonSidePanel.handleAddCohortClick(${i});"
          role="img"
          aria-hidden="true"
          >add</mat-icon
        >
        <mat-icon
          matTooltip="Subtract this cohort from the selection"
          class="material-icons common-side-cohort-list-delete"
          style="font-size: 16px"
          onclick="window.reachableOncoData.currentCommonSidePanel.handleSubtractCohortClick(${i});"
          role="img"
          aria-hidden="true"
          >remove</mat-icon
        >
        <mat-icon
          matTooltip="Delete this cohort"
          class="material-icons common-side-cohort-list-delete"
          style="font-size: 16px"
          title="Delete cohort"
          onclick="window.reachableOncoData.currentCommonSidePanel.handleDeleteCohortClick(${i});"
          role="img"
          aria-hidden="true"
          >delete</mat-icon
        >
        `;
            }
            innerTBody =
                innerTBody +
                    `
        <tr>
          <td width="10" bgcolor="${color}">
            &nbsp;
          </td>
          <td>&nbsp;</td>
          <td
            width="100%"
            height="23"
            class="common-side-cohort-list-item"
            onclick="window.reachableOncoData.currentCommonSidePanel.handleSavedCohortClick(${i}); "
          >
            ${nameDescription}
          </td>
          <td nowrap style="font-size: 12px; vertical-align: middle">
            ${deleteCellContents}
          </td>
        </tr>

        `;
        }
        window.setTimeout(() => {
            MyTable.innerHTML = `<tbody>${innerTBody}</tbody>`;
        }, 20);
    }
    handleStatsCohortClick(index) {
        // alert("Stats button not yet implemented. Go to Analysis tab, and choose Dashboard. Then select this cohort from dropdown, and another cohort to compare it with.");
        Khonsole.warn(`stats request ${index}`);
        let cohort = OncoData.instance.currentCommonSidePanel.commonSidePanelModel
            .definedCohorts[index];
        window["cohortRequestingDashboard"] = cohort;
        WorkspaceComponent.instance.setPanel(2048 /* DASHBOARD */);
    }
    handleAddCohortClick(index) {
        alert("Add button not yet implemented. To add to the current selection, hold SHIFT key and click this cohort's name.");
    }
    handleSubtractCohortClick(index) {
        alert("Subtract button not yet implemented. To subtract from the current selection, hold ALT key and click this cohort's name.");
    }
    handleDeleteCohortClick(index) {
        if (window.confirm("Are you sure you want to DELETE this cohort? This cannot be undone.")) {
            let self = this;
            if (index > 0 &&
                index <= this.commonSidePanelModel.definedCohorts.length) {
                this.commonSidePanelModel.lastSelectedDefinedCohort = null;
                let c = this.commonSidePanelModel.definedCohorts[index];
                WorkspaceComponent.instance.delCohort({
                    database: self.config.database,
                    cohort: c,
                });
                window.setTimeout(() => {
                    self.drawWidgets();
                }, 350);
            }
            else {
                alert(`Could not find defined cohort with index of ${index}.`);
            }
        }
    }
    createCohortFromPatientSelectionIds({ selectionIds, ignoreIdCase = false, preferredColor = null, }) {
        // compute a Set of sampleIds. add to cohort.
        // WARNING: we are assuming one sample per patient!! MJ TODO TBD
        let sampleSet = new Set([]);
        let newCohort;
        try {
            selectionIds.forEach((id) => {
                let sidKey;
                if (ignoreIdCase) {
                    sidKey = Object.keys(this.commonSidePanelModel.patientMap).find((key) => key.toLowerCase() === id.toLowerCase());
                }
                else {
                    sidKey = id;
                }
                if (sidKey) {
                    sampleSet.add(this.commonSidePanelModel.patientMap[sidKey]);
                }
            });
            let sampleIds = Array.from(sampleSet);
            newCohort = {
                n: "new" + Math.random() + "_" + Date.now(),
                pids: selectionIds,
                sids: sampleIds,
                conditions: [],
                fromSelection: true,
                preferredColor,
            };
        }
        catch (err) {
            Khonsole.error("createCohortFromPatientSelectionIds error...");
            Khonsole.dir(err);
        }
        return newCohort;
    }
    addCohortClicked(e) {
        let self = this;
        let newCohort = this.createCohortFromPatientSelectionIds({
            selectionIds: self.commonSidePanelModel.selectionIds,
        });
        newCohort.n = this.newCohortsWidget.selectedCohortText.nativeElement.value;
        // grab the assigned color from either the variable or the UI (UI will be needed for "All" cohort and legacy cohorts)
        newCohort.assignedColor = this.commonSidePanelModel.selectionAssignedColor ||
            this.newCohortsWidget.selectedCohortColorCell.nativeElement.getAttribute("bgcolor");
        newCohort.preferredColor = this.commonSidePanelModel.selectionPreferredColor;
        WorkspaceComponent.instance.addCohort({
            database: self.config.database,
            cohort: newCohort,
        });
        window.setTimeout(() => {
            self.drawWidgets();
        }, 350);
    }
    widgetDrop(event) {
        Khonsole.log(`widgetDrop in common side panel from[${event.previousIndex}] to [${event.currentIndex}].`);
        //moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    }
    applySelectedCohortNameFilter(filterValue) {
        // filterValue = filterValue.trim();
        // filterValue = filterValue.toLowerCase();
        // this.dataSource.filter = filterValue;
        //=====.log(Date.now().toString());
        //document.activeElement.blur()
    }
    drawWidgets() {
        let self = this;
        this.dataService
            .getCustomCohorts(this.config.database)
            .then((retrievedCustomCohorts) => {
            this.commonSidePanelModel.definedCohorts = retrievedCustomCohorts;
            self.populateCohortsHtmlTable(self.commonSidePanelModel.definedCohorts);
        });
        if (this.newSurvivalWidget) {
            window.setTimeout(() => this.newSurvivalWidget.drawSurvivalWidget(), 50);
        }
        if (this.newCopynumberWidget) {
            window.setTimeout(() => this.newCopynumberWidget.drawCopynumbers(), 50);
        }
        if (this.newDiffexpWidget) {
            window.setTimeout(() => this.drawDiffexp(), 50);
        }
        if (this.newCohortsWidget) {
            window.setTimeout(() => this.drawHtml(), 50);
        }
    }
    visuallyClearWidgets() {
        if (this.newSurvivalWidget) {
            this.newSurvivalWidget.clearContents();
        }
        if (this.newCopynumberWidget) {
            this.newCopynumberWidget.clearContents();
        }
        if (this.newDiffexpWidget) {
            this.newDiffexpWidget.clearContents();
        }
        if (this.newCohortsWidget) {
            this.newCohortsWidget.clearContents();
        }
    }
    drawHtml() {
        return __awaiter(this, void 0, void 0, function* () {
            Khonsole.log("drawHtml for widget");
        });
    }
    drawDiffexp() {
        return __awaiter(this, void 0, void 0, function* () {
            let self = this;
            Khonsole.warn("drawDiffexp");
            let HandcodedDifferentiableTableName = "rna";
            let firstDifferentiableMatrixTable = OncoData.instance.dataLoadedAction.tables.find((v) => v.ctype == 256 /* MRNA */ ||
                v.ctype == 32768 /* RNA */);
            // if(firstDifferentiableMatrixTable == null) {
            //   Khonsole.warn('Try for GSVA.');
            //   firstDifferentiableMatrixTable = OncoData.instance.dataLoadedAction.tables.find(v => v.ctype == CollectionTypeEnum.GENESET_SCORE);
            // }
            // if(firstDifferentiableMatrixTable == null) {
            //   Khonsole.warn('Try for CNA.');
            //   firstDifferentiableMatrixTable = OncoData.instance.dataLoadedAction.tables.find(v => v.ctype == CollectionTypeEnum.GISTIC || v.ctype == CollectionTypeEnum.GISTIC_THRESHOLD );
            // }
            if (firstDifferentiableMatrixTable == null) {
                Khonsole.warn("Need to ensure all mRNA tables import as MRNA, not MATRIX.");
                firstDifferentiableMatrixTable =
                    OncoData.instance.dataLoadedAction.tables.find((v) => v.ctype == 262144 /* MATRIX */);
            }
            if (firstDifferentiableMatrixTable) {
                HandcodedDifferentiableTableName = firstDifferentiableMatrixTable.tbl;
                // if(window[debugRnaLoadKey] == null) {
                //   window.alert("******** SKIP LOADING RNA *****");
                // }
                // window[debugRnaLoadKey]=true;
                // 262144 is CollectionTypeEnum.MATRIX. Use that if we don't have
                // a window.reachableOncoData.dataLoadedAction.tables with ctype == CollectionTypeEnum.MRNA
                if (WorkspaceComponent.instance.hasLoadedTable(HandcodedDifferentiableTableName) == false) {
                    WorkspaceComponent.instance.requestLoadedTable(HandcodedDifferentiableTableName);
                    window.setTimeout(() => this.drawDiffexp(), 200);
                    return null;
                }
                if (true) {
                    // =======this.commonSidePanelModel.tableNameUsedForCopynumber) {
                    // continue
                }
                else {
                    Khonsole.log("Not drawing diffexp widget, because config.table is not of type required.");
                    return;
                }
                let el = document.querySelector("#svgContainer_Differential_Expression");
                let svg = d3.select(el.getElementsByTagName("svg")[0]);
                this.newDiffexpWidget.drawSvg(svg, {
                    tableName: HandcodedDifferentiableTableName,
                });
            }
            else {
                Khonsole.warn("In drawDiffexp, should show error in newDiffexpWidget, because no usable table really exists.");
            }
        });
    }
    processConfigChangeAfterGenomeLoaded() {
        return __awaiter(this, void 0, void 0, function* () {
            let self = this;
            if (this.newSurvivalWidget) {
                this.newSurvivalWidget.processConfigChange(this.config);
            }
            if (this.newCopynumberWidget) {
                this.newCopynumberWidget.processConfigChange(this.config);
            }
            if (this.newDiffexpWidget) {
                this.newDiffexpWidget.processConfigChange(this.config);
            }
            if (this.newCohortsWidget) {
                this.newCohortsWidget.processConfigChange(this.config);
            }
            this.setupAllGenes().then((sagResult) => {
                let getAllTablesPromise = null;
                if (this.commonSidePanelModel.tableNameUsedForCopynumber == null ||
                    this.commonSidePanelModel.lastCopynumberProcessedDatabase !=
                        self._config.database) {
                    // Need to get list of tables in this database, see if one
                    // is of type gistic_threshold, for copynumber widget.
                    getAllTablesPromise = this.dataService.getDatasetTables(this._config.database);
                }
                Promise.all([getAllTablesPromise]).then((getTablesResult) => {
                    if (getTablesResult[0] == null) {
                        Khonsole.log("Error? getTablesResult is null, meaning we did not find all tables again.");
                    }
                    else {
                        let tableArray = getTablesResult[0];
                        let thresholdCopyNumberTables = tableArray.filter((table) => table.ctype == 32 /* GISTIC_THRESHOLD */);
                        let nonThresholdCopyNumberTables = tableArray.filter((table) => table.ctype == 16 /* GISTIC */ ||
                            table.ctype == 8 /* CNV */ ||
                            table.tbl.toLowerCase() == "nn_data_cna" // Hardcoded hack for NN glioma. -MJ TBD
                        );
                        let copyNumberTables = thresholdCopyNumberTables.concat(nonThresholdCopyNumberTables);
                        this.tablesThatCouldBeUsedForCopynumber = copyNumberTables;
                        if (copyNumberTables.length > 0) {
                            Khonsole.log(`====> There is a copy number table for copynumber: [${copyNumberTables[0].tbl}].`);
                            this.commonSidePanelModel.tableNameUsedForCopynumber =
                                copyNumberTables[0].tbl;
                        }
                        else {
                            Khonsole.log(`====> There is no copy number table for copynumber.`);
                            this.commonSidePanelModel.tableNameUsedForCopynumber = null;
                        }
                    }
                    self.dataService
                        .getPatientData("notitia-" + self._config.database, "patient")
                        .then((result) => {
                        this.commonSidePanelModel.patientData = result.data;
                        this.commonSidePanelModel.patientMap = result.patientMap;
                        this.commonSidePanelModel.sampleMap = result.sampleMap;
                        self.updateSurvival();
                        self.drawWidgets(); // copynumbers widget goes into a loop unitl it's marked ready for drawing. This lets survival widget draw right away.
                        if (this.newCopynumberWidget) {
                            // window.alert('*** SKIPPING CNA ***');
                            this.newCopynumberWidget
                                .loadCNAAndFilterIfNeeded(this.newCopynumberWidget, this.config)
                                .then(function (v) {
                                Khonsole.log("MJ loaded cna data (if needed) within processConfigChangeAfterGenomeLoaded.");
                            });
                        }
                    });
                });
            });
        });
    }
    set legends(value) {
        if (value === null) {
            Khonsole.log(`TEMPNOTE: Input for legend-panel was null.`);
            return;
        }
        this._legends = value;
        this.updateSurvival();
    }
    select() { }
    deselect() { }
    ngAfterViewInit() {
        Khonsole.warn("commonside ngAfterViewInit");
        this.wireCspToWidgets();
    }
    wireCspToWidgets() {
        if (this.newSurvivalWidget) {
            this.newSurvivalWidget.commonSidePanelModel =
                CommonSidePanelComponent.instance.commonSidePanelModel;
        }
        if (this.newCopynumberWidget) {
            this.newCopynumberWidget.commonSidePanelModel =
                CommonSidePanelComponent.instance.commonSidePanelModel;
        }
        if (this.newDiffexpWidget) {
            this.newDiffexpWidget.commonSidePanelModel =
                CommonSidePanelComponent.instance.commonSidePanelModel;
        }
        if (this.newCohortsWidget) {
            this.newCohortsWidget.commonSidePanelModel =
                CommonSidePanelComponent.instance.commonSidePanelModel;
        }
    }
    ngOnDestroy() { }
    ngOnChanges(changes) {
        let defaultTourAndVersion = "main.1";
        let tourOfInterest = defaultTourAndVersion;
        if (OncoData.instance.dataLoadedAction.datasetTableInfo &&
            OncoData.instance.dataLoadedAction.datasetTableInfo.tour) {
            tourOfInterest =
                OncoData.instance.dataLoadedAction.datasetTableInfo.tour.tourName;
        }
        // Khonsole.log('trkchanges');
        // for (const propName in changes) {
        //   Khonsole.log(`ngOnChanges ${propName} changed.`);
        // }
        this.commonSidePanelModel.dataService = this.dataService;
        this.commonSidePanelModel.datasetDescription = this.datasetDescription;
        Khonsole.log("datasetDescription updated");
        this.wireCspToWidgets();
        let shouldTakeTour = false;
        let toursSeen = localStorage.getItem("toursSeen");
        // format is "main.1,other.1" CSV, with name.version. Replace e.g., "other" in future with dataset-specific tour.
        if (toursSeen == null) {
            shouldTakeTour = true;
        }
        else {
            // Parse CSV and look for tourOfInterest
            let fullToursString = `,${toursSeen},`;
            shouldTakeTour = fullToursString.includes(`,${tourOfInterest},`) == false;
        }
        if (shouldTakeTour) {
            let newToursSeenString;
            if (toursSeen) {
                newToursSeenString = toursSeen + "," + tourOfInterest;
            }
            else {
                newToursSeenString = tourOfInterest;
            }
            localStorage.setItem("toursSeen", newToursSeenString);
            let self = this;
            window.setTimeout(self.startTour, 100);
        }
    }
    generateTabsHtml(tourStep) {
        let tabsHtml = `<div class="tour-tab-container" >
    <div class="tour-tabs">`;
        tourStep.tabs.forEach((tab, index) => {
            tabsHtml += `  <button class="tour-tab-button ${index === 0 ? "active" : ""}" onclick="openTourstepTab(event, 'tourstep_tab${index}')">\n`;
            tabsHtml += `    ${tab.title}\n`;
            tabsHtml += "  </button>\n";
        });
        tabsHtml += "</div>"; // tour-tabs
        tourStep.tabs.forEach((tab, index) => {
            tabsHtml += `<div id="tourstep_tab${index}" class="tour-tab-content" style="display: ${index === 0 ? "block" : "none"};">\n`;
            // escape content
            var e = document.createElement("csp_tour_unescape_textarea");
            e.innerHTML = tab.content;
            // handle case of empty input
            let unesc_html = e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
            tabsHtml += unesc_html;
            tabsHtml += "</div>\n"; // tour-tab-content
        });
        tabsHtml += "</div>"; // tour-tab-container
        return tabsHtml;
    }
    startTour() {
        // TBD: why is "this" the window object now?
        let startTourSelf = CommonSidePanelComponent.instance; // this;
        introJs().addHints();
        let steps = [
            {
                title: "Navigation",
                intro: `This tour highlights our tools for exploring molecular and clinical data. <br>
        First, navigating a scatter plot:<br><br>
        <img width="450" src="/assets/videos/OncoTour_ScatterMovements.gif" />
`
            },
            {
                title: "Selections",
                intro: `
        <ul>
        <li>To select points, hold Shift, then click-drag like a lasso.<br></li>
        <li>To deselect all points, click on the background.</li>
        </ul><br>
        <img width="450" src="/assets/videos/OncoTour_Selections.gif" />
        `
            },
            {
                title: "Color Data",
                intro: `
        Set a color legend, then use it to access subsets.<br><br>
        <img width="450" src="/assets/videos/OncoTour_Color.gif" />
        `
            },
            {
                title: "Cohorts",
                intro: `
        Create a cohort from any selection.<br><br>
        <img width="450" src="/assets/videos/OncoTour_Cohorts.gif" />
        `
            } //,
            // {
            //   element: document.querySelector('#svgContainer_Survival'),
            //   intro: 'When you select points or a cohort, their survival information appears as a new plot line.'
            // }
        ];
        let customTour = null;
        if (OncoData.instance.dataLoadedAction.datasetTableInfo &&
            OncoData.instance.dataLoadedAction.datasetTableInfo.tour) {
            customTour = OncoData.instance.dataLoadedAction.datasetTableInfo.tour;
            // Prepend the dataset's own tour steps. Reverse them then prepend them to steps, so they stay in original order.
            // Turn update's {title, html} in to tour's {title, intro}. Set the step title to " " to indicate it is special first step for dataset.
            let datasetTourSteps = customTour.steps;
            let datasetTourStepsReversed = [...datasetTourSteps].reverse();
            if (customTour && customTour.skipUsualSteps) {
                steps = [];
            }
            datasetTourStepsReversed.forEach((dt_step) => {
                let html = dt_step.html;
                // if (dt_step.escapedHtml != null) {
                //   let esc_html = dt_step.escapedHtml;
                //   var e = document.createElement('csp_tour_unescape_textarea');
                //   e.innerHTML = esc_html;
                //   // handle case of empty input
                //   let unesc_html = e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
                //   html = html + "<br>" + unesc_html;
                // }
                if (dt_step.tabs != null) {
                    html =
                        html +
                            "<br>" +
                            OncoData.instance.currentCommonSidePanel.generateTabsHtml(dt_step);
                    let esc_html = dt_step.escapedHtml;
                }
                let step = {
                    title: dt_step.title,
                    intro: html,
                };
                steps.unshift(step);
            });
        }
        // set class width for tooltip
        //introjs - tooltip {
        //  min - width: 220px
        this.introJsFunction = introJs()
            .setOptions({
            steps: steps,
            tooltipClass: "introjs-tooltip",
        })
            .onexit(function () {
            Khonsole.log("...currentstep??");
            window.setTimeout(function () {
                Khonsole.log("... about to call startTourReminder");
                startTourSelf.startTourReminder();
            }, 50);
        })
            .onafterchange(function (targetEl) {
            // Use custom tour width if there is one.
            const element = document.querySelector(".introjs-tooltip.introjs-floating");
            if (element) {
                if (customTour && customTour.width) {
                    element.style.minWidth = customTour.width + "px";
                }
                else {
                    element.style.minWidth = "220px";
                }
            }
        })
            .start();
    }
    startTourReminder() {
        Khonsole.log("... inside startTourReminder");
        let steps = [
            {
                title: "Done",
                element: document.querySelector("#takeTourBtn"),
                intro: "If you want to start this tour again, click this link.",
            },
        ];
        this.introJsFunction = introJs()
            .setOptions({
            steps: steps,
        })
            .onexit(function () {
            Khonsole.log("...currentstep??");
            window.setTimeout(function () {
                Khonsole.log("=== === === === ===");
                Khonsole.log("(final cleanup in startTourReminder onexit.)");
                Khonsole.log("=== === === === ===");
                let ijElList = document.getElementsByClassName("introjs-overlay");
                if (ijElList.length > 0) {
                    ijElList[0].remove();
                }
                ijElList = document.getElementsByClassName("introjs-helperLayer");
                if (ijElList.length > 0) {
                    ijElList[0].remove();
                }
            }, 150);
            // try introJS()._currentstep
            //return confirm("To restart the tour, click 'Take A Tour' on the blue menu bar. Are you sure you want to stop the tour?");
        })
            .start();
    }
    update() {
        if (!this.autoUpdate) {
            return;
        }
        try {
            // const legends = this._legends.map(legend => this.legendFormatter(legend));
            // this.allLegends = [].concat(...decorators, ...legends);
            this.cd.detectChanges();
        }
        catch (err) {
            Khonsole.error(`TEMPNOTE: error in legend update, probably bad _legends. ${err}`);
        }
    }
    onSetLegends(e) {
        if (this.config.graph !== e.graph) {
            return;
        }
        this.autoUpdate = false;
        // this.allLegends = e.legend;
        this.cd.detectChanges();
    }
    setupAllGenes() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.commonSidePanelModel.genesData == null) {
                let config_alignment = "19";
                let gg = yield this.wutil.getGenomePositions(config_alignment);
                this.oncoBandsData = gg[0];
                this.commonSidePanelModel.genesData = gg[1];
            }
            return null;
        });
    }
    notifyGraphsOfVariantChanges(reason) {
        Khonsole.log(`notifyGraphsOfVariantChanges because ${reason}.`);
        OncoData.instance.variantsEdgeArray = null;
        // TODO: check each view, send update.
        ChartScene.instance.views.map((v) => {
            if (v.chart) {
                v.chart.notifiedOfVariantChanges(reason);
            }
        });
    }
}
CommonSidePanelComponent.setSurvival = new EventEmitter();
