<app-workspace-chart (onSelect)="select($event)" (configChange)="graphPanelSetConfig($event)"></app-workspace-chart>


<div #leftPanelContainer ><!--style="pointer-events: none;" -->
  <!-- <app-workspace-tool-bar *ngIf='graphPanelATab | async'></app-workspace-tool-bar>  -->

  <ul id="custom-menu" class='custom-menu'>
    <li class="custom-menu-subgroup">This Item
      <ul  >
        <li style="color: #444" class="custom-menu-item" data-action="copyids">Copy ID To Clipboard</li>
        <li class="custom-menu-item" data-action="deselect">Deselect</li>
        <!--<li class="custom-menu-item" data-action="itemreturntoorigin">Return To Origin</li>-->
      </ul>
    </li>
    <li class="custom-menu-subgroup">Selected Items
      <ul  >
        <li style="color: #444" class="custom-menu-item" data-action="copyids">Copy IDs To Clipboard</li>
        <li style="color: #444" class="custom-menu-item" data-action="invertselection">Invert Selection</li>
        <!--<li style="color: #444" class="custom-menu-item" data-action="selectionreturntoorigin">Return To Origin</li>-->
        <li style="color: #444" class="custom-menu-item" data-action="deselectunlinked">Deselect Unlinked Items</li>
        <!--<li style="color: #444" class="custom-menu-item" data-action="createset">Create Set</li>-->
      </ul>
    </li>
    <!--
    <li class="custom-menu-subgroup">All Items
      <ul style="color: #444" >
        <li style="color: #444" class="custom-menu-item" data-action="allreturntoorigin">Return To Origin</li>
      </ul>
    </li>
  -->
  </ul>

  <app-workspace-info-panel
    [workspaceConfig]="workspaceConfig | async"
    [graphAConfig]="graphAConfig | async"
    [graphBConfig]="graphBConfig | async"
  ></app-workspace-info-panel>
  <app-workspace-graph-panel
    style="position:absolute;top:30px;left:0px;bottom:0px;"
    *ngIf="(graphPanelATab | async)"
    [title]="'Graph A'"
    [events]="events | async"
    [tables]="tables | async"
    [fields]="fields | async"
    [pathways]="pathways | async"
    [genesets]="genesets | async"
    [cohorts]="cohorts | async"
    [preprocessings]="preprocessings | async"
    [datasetDescription]="datasetDescription | async"
    [decorators]="graphADecorators | async"
    [legendFilters]="graphALegendFilters | async"
    (decoratorAdd)="graphPanelAddDecorator($event)"
    (decoratorDel)="graphPanelDelDecorator($event)"
    (decoratorDelAll)="graphPanelDelAllDecorators($event)"
    (legendFilterAdd)="graphPanelAddLegendFilter($event)"
    (legendFilterDel)="graphPanelDelLegendFilter($event)"
    (legendFilterDelAll)="graphPanelDelAllLegendFilters($event)"
    (saveSelection)="saveSelection($event)"
    (selectionToolChange)="graphPanelSetSelectionToolConfig($event)"
    [selectionToolConfig]="graphASelectionToolConfig | async"
    [config]="graphAConfig | async"
    [data]="graphAData | async"
    (help)="helpPanelToggle($event)"
    (configChange)="graphPanelSetConfig($event)"
    (showPanel)="setPanel($event)"
    (threeDOption)="setThreeDOptions($event)"
  >
  </app-workspace-graph-panel>

  <graph-widgets-panel
      [datasetDescription]="datasetDescription | async"
      [config]="graphAConfig | async"
      [data]="graphAData | async"

      style="z-index:2; position:absolute;top:89px;right:0px; width:274px;  border-width:1px;
      border-style: solid;
      border-color: lightgray;
      background-color: white;">
  </graph-widgets-panel>


  <app-workspace-graph-panel
    style="pointer-events: none;position:absolute;top:30px;right:275px;bottom:0px;"
    *ngIf="(graphPanelBTab | async)"
    [title]="'Graph B'"
    [events]="events | async"
    [tables]="tables | async"
    [fields]="fields | async"
    [pathways]="pathways | async"
    [genesets]="genesets | async"
    [cohorts]="cohorts | async"
    [preprocessings]="preprocessings | async"
    [datasetDescription]="datasetDescription | async"
    [decorators]="graphBDecorators | async"
    (decoratorAdd)="graphPanelAddDecorator($event)"
    (decoratorDel)="graphPanelDelDecorator($event)"
    (decoratorDelAll)="graphPanelDelAllDecorators($event)"
    (legendFilterAdd)="graphPanelAddLegendFilter($event)"
    (legendFilterDel)="graphPanelDelLegendFilter($event)"
    (legendFilterDelAll)="graphPanelDelAllLegendFilters($event)"
    (saveSelection)="saveSelection($event)"
    (selectionToolChange)="graphPanelSetSelectionToolConfig($event)"
    [selectionToolConfig]="graphBSelectionToolConfig | async"
    [config]="graphBConfig | async"
    [data]="graphBData | async"
    (help)="helpPanelToggle($event)"
    (configChange)="graphPanelSetConfig($event)"
    (showPanel)="setPanel($event)"
    (threeDOption)="setThreeDOptions($event)"
    [edgeDecorators]="edgeDecorators | async"
  >
    <app-workspace-edge-panel
      [graphAConfig]="graphAConfig | async"
      [graphBConfig]="graphBConfig | async"
      [workspaceConfig]="workspaceConfig | async"
      [tables]="tables | async"
      [fields]="fields | async"
      (edgeConfigChange)="edgeConfigChange($event)"
      [decorators]="edgeDecorators | async"
      (decoratorAdd)="edgeAddDecorator($event)"
      (decoratorDel)="edgeDelDecorator($event)"
    >
    </app-workspace-edge-panel>
  </app-workspace-graph-panel>
</div>

<!-- Application Menu Bar -->
<app-application-bar
  (splitScreenChange)="splitScreenChange($event)"
  (showPanel)="setPanel($event)"
  [config]="graphAConfig | async"
></app-application-bar>

<!-- Panels -->
<app-workspace-landing-panel
  *ngIf="(modalPanel | async) === 2"
  class="modal-panel landing-panel"
  style="z-index:999999"
  (onGetStarted)="setPanel(4)"
></app-workspace-landing-panel>

<app-workspace-tip-panel
  *ngIf="(tipVisible | async) && (tipEnabled | async)"
  (hide)="tipHide()"
  [tips]="tip | async"
></app-workspace-tip-panel>

<app-workspace-file-panel
  *ngIf="(modalPanel | async) === 4"
  (hide)="setPanel(4)"
  class="modal-panel"
  style="z-index:300"
  (loadPublic)="fileLoadPublic($event)"
  (loadPrivate)="fileLoadPrivate($event)"
  (showPanel)="setPanel($event)"
></app-workspace-file-panel>
<app-workspace-cohort-panel
  *ngIf="(modalPanel | async) === 16"
  (hide)="setPanel(1)"
  (addCohort)="addCohort($event)"
  (delCohort)="delCohort($event)"
  [cohorts]="cohorts | async"
  [config]="graphAConfig | async"
  class="modal-panel"
></app-workspace-cohort-panel>
<app-workspace-geneset-panel
  *ngIf="(modalPanel | async) === 32"
  (hide)="setPanel(1)"
  (addGeneset)="addGeneset($event)"
  (delGeneset)="delGeneset($event)"
  (updateGeneset)="updateGeneset($event)"
  [genesets]="genesets | async"
  [config]="graphAConfig | async"
  class="modal-panel"
></app-workspace-geneset-panel>
<app-workspace-pathway-panel
  *ngIf="(modalPanel | async) === 8192"
  (hide)="setPanel(1)"
  (addPathway)="addPathway($event)"
  (delPathway)="delPathway($event)"
  [pathways]="pathways | async"
  [config]="graphAConfig | async"
  class="modal-panel"
></app-workspace-pathway-panel>
<app-workspace-upload-panel
  *ngIf="(modalPanel | async) === 32768"
  (hide)="setPanel(1)"
  class="modal-panel"
></app-workspace-upload-panel>
<app-workspace-preprocessing-panel
  *ngIf="(modalPanel | async) === 2199023255552"
  (addPreprocessing)="addPreprocessing($event)"
  (delPreprocessing)="delPreprocessing($event)"
  [preprocessings]="preprocessings | async"
  [config]="graphAConfig | async"
  (hide)="setPanel(1)"
  class="modal-panel"
></app-workspace-preprocessing-panel>
<app-workspace-feedback-panel
  *ngIf="(modalPanel | async) === 16384"
  (hide)="setPanel(1)"
  class="modal-panel"
></app-workspace-feedback-panel>
<app-workspace-help-panel
  *ngIf="(modalPanel | async) === 128"
  (hide)="setPanel(1)"
  class="modal-panel"
  [config]="helpPanelConfig | async"
></app-workspace-help-panel>
<app-workspace-about-panel
  *ngIf="(modalPanel | async) === 512"
  (hide)="setPanel(1)"
  class="modal-panel"
></app-workspace-about-panel>
<app-workspace-citations-panel
  *ngIf="(modalPanel | async) === 1024"
  (hide)="setPanel(1)"
  class="modal-panel"
></app-workspace-citations-panel>
<app-workspace-dashboard-panel
  *ngIf="(modalPanel | async) === 2048"
  (hide)="setPanel(1)"
  class="modal-panel"
  [cohorts]="cohorts | async"
  [config]="graphAConfig | async"
></app-workspace-dashboard-panel>
<app-workspace-data-panel
  *ngIf="(modalPanel | async) === 1099511627776"
  (hide)="setPanel(1)"
  class="modal-panel"
  [tables]="tables | async"
  [configA]="graphAConfig | async"
  [configB]="graphBConfig | async"
></app-workspace-data-panel>
<!--
  <app-workspace-selection-panel
    *ngIf="(selectVisible | async)"
    (hide)="hideSelectionPanel()"
    (saveCohort)="saveCohort($event)"
    (saveGeneset)="saveGeneset($event)"
    [selection]="selectSelection | async"
    [stats]="selectStats | async"
  ></app-workspace-selection-panel>
-->

<!-- Loader -->
<!-- *ngIf='(loader | async) ' -->
<app-workspace-loader *ngIf="(loader | async)"></app-workspace-loader>
