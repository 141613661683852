import { Khonsole } from 'app/khonsole';
import { DatasetDescription } from 'app/model/dataset-description.model';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
  ChangeDetectorRef,
  Input
} from '@angular/core';
import { PanelEnum } from 'app/model/enum.model';
import { WorkspaceComponent } from '../../workspace.component';
import { OncoData } from 'app/oncoData';
@Component({
  selector: 'app-graph-panel-analysis',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: "graph-panel-analysis.component.html",
})
export class GraphPanelAnalysisComponent {
  @Output()
  select: EventEmitter<any> = new EventEmitter();

  @Output()
  showPanel: EventEmitter<PanelEnum> = new EventEmitter<PanelEnum>();


  private _datasetDescription: DatasetDescription;

  public handleTimelineClick( oldStyle){
    Khonsole.log('timeline click...')
    if(oldStyle) {
      window["currentTimelineType"] = "v3"
    } else {
      window["currentTimelineType"] = "svg"
    }
    Khonsole.log('timeline ='+window["currentTimelineType"])
    this.select.emit(128);
  }

  public shouldShowAnalysisItem(itemName: string) {
    let itemNameLower = itemName.toLowerCase();
    let show = true;
    if (OncoData.instance.dataLoadedAction && OncoData.instance.dataLoadedAction.dataset) {
      let ux = OncoData.instance.dataLoadedAction.datasetTableInfo.ux;
      if (ux && ux.hidden && ux.hidden.indexOf(itemNameLower) >= 0) {
        show = false;
      }
    } else {
      Khonsole.log("shouldShowAnalysisItem called before DLA.")   
    }
    return show;
  }

  public handleSpreadsheetClick() {
     this.select.emit(1099511627776)
  }

  public handleCohortsClick(){
    Khonsole.log('handleCohortClick      click...');
    WorkspaceComponent.instance.setPanel(PanelEnum.COHORT);
    // this.showPanel.emit(PanelEnum.COHORT);
  }

  public handleGenesetsClick(){
    Khonsole.log('handleGenesetsClick      click...')
    WorkspaceComponent.instance.setPanel(PanelEnum.GENESET);
  }

  @Input()
  public set datasetDescription(value: DatasetDescription) {
    this._datasetDescription = value;
    this.cd.markForCheck();
  }
  public get datasetDescription(): DatasetDescription {
    return this._datasetDescription;
  }

  constructor(public cd: ChangeDetectorRef) {}
}
