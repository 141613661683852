var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Khonsole } from 'app/khonsole';
import { MatSelectChange } from '@angular/material';
import { OnInit, OnDestroy, OnChanges, AfterContentChecked, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { EventEmitter, NgZone } from '@angular/core';
import { GraphConfig } from 'app/model/graph-config.model';
import { DataField, DataFieldFactory } from 'app/model/data-field.model';
import Dexie from 'dexie';
import { OncoData } from 'app/oncoData';
import { TableIdList } from 'app/model/dataset-table-info.model';
import { DatasetService } from '../../../../service/dataset.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
export class ColorbyForm {
    constructor(cd, datasetService, zone) {
        this.cd = cd;
        this.datasetService = datasetService;
        this.zone = zone;
        this.colorbyControl = new FormControl();
        this.lastSelectedColorbyId = null;
        this.selectedOption = 'metadata';
        this.unset_table_placeholder = "(unset)";
        this.data = {
            id: null,
            tableName: this.unset_table_placeholder,
            // event: MatSelectChange;
            ids: []
        };
        this._tablesWithIds = []; // e.g., ['rna', 'gsva']
        this._tableIdLists = []; // Connects to tablesWithIds list... [['GENE1",'GENE2'], ['GOSCOREA', 'GOSCOREB', 'GSCOREC'] ]
        this._selectedTblIndex = 0;
        this.featureColorOptions = []; // Clinical features we can color by (["age", "sex"]), not table-based.
        // New event emitter
        this.onApplyColorbyMatSelectChange = new EventEmitter();
        this.selectedTblIndex = 0;
        if (this.tableIdLists && this.tablesWithIds.length > 0) {
            Khonsole.warn(`colorbyform constructor, table = [${this.tablesWithIds[this.selectedTblIndex]}]`);
        }
        else {
            Khonsole.warn(`colorbyform constructor NO table`);
        }
    }
    get config() {
        return this._config;
    }
    set config(config) {
        this._config = config;
    }
    set tablesWithIds(s) {
        this._tablesWithIds = s;
    }
    get tablesWithIds() {
        return this._tablesWithIds;
    }
    set tableIdLists(s) {
        this._tableIdLists = s;
    }
    get tableIdLists() {
        return this._tableIdLists;
    }
    set selectedTblIndex(s) {
        this._selectedTblIndex = s;
    }
    get selectedTblIndex() {
        return this._selectedTblIndex;
    }
    set selectedTbl(s) {
        this._selectedTbl = s;
    }
    get selectedTbl() {
        return this._selectedTbl;
    }
    ngOnInit() {
        if (this.config) {
            let graph = this.config.graph; // 1 = left, 2 = right
        }
        this.forceFormUpdateAndEmit("", null);
    }
    ngAfterContentChecked() {
    }
    formatDropdownTableName(tbl) {
        if ((this._config.database == "bulkrnaseqbrainumap" || this._config.database.startsWith("medulloblastomaumap")) && tbl.toUpperCase() == "GSVA") {
            return "PATHWAYS";
        }
        else {
            if ((this._config.database.startsWith("meningiomaumap") || this._config.database == "version_89.zip_2024-01-02T18-23-39.15_8820") && tbl.toUpperCase() == "CNAARM") {
                return "COPY NUMBER (CHR ARM)";
            }
            else {
                return tbl.toUpperCase();
            }
        }
    }
    cloneDataFieldFromColorById(colorOptions, colorbyId) {
        let thisKey = colorbyId; // split to parts and get
        if (colorbyId.startsWith("ColorBy:")) {
            thisKey = "ColorBy";
        }
        let dataField = this.colorOptions.find(co => co.key == thisKey);
        if (dataField) {
            let clonedDataField = __rest(dataField, []);
            if (colorbyId.startsWith("ColorBy:")) {
                clonedDataField.key = "ColorBy";
                clonedDataField.label = colorbyId;
                clonedDataField.tbl = colorbyId.split(':')[1].split('=')[0];
                clonedDataField.ctype = 262144 /* MATRIX */; // Why does the incoming value vary?
            }
            return clonedDataField;
        }
        else {
            Khonsole.error("No DataField for key [" + thisKey + "]");
        }
        return null;
    }
    /**
     * Artifically force the form to update and emit the new color by value so the graph will update. This is useful when you need to update the form from some external component.
     * @param value The colorBy Value (can be from a table or from a metadata field)
     * @param table The table, if any
     * @returns
     */
    forceFormUpdateAndEmit(value, table) {
        if (table) {
            // update the value in the DOM
            const displayValue = value.split('=')[1];
            this.colorbyControl.setValue(displayValue);
            // trigger the change (emit)
            this.onTableChange({ target: { value: table } }, false);
            this.onTableValueChange({ option: { value: value } });
        }
        else {
            // update the value in the DOM
            const colorDec = {
                type: 0 /* COLOR */,
                values: [],
                field: Object.assign({}, DataFieldFactory.getUndefined(), { label: value }),
                legend: null,
                pidsByLabel: null,
                config: null,
                customPalette: null
            };
            this.metadataValue = colorDec.field;
            // trigger the change (emit)
            this.onMetadataValueChange({ value: { key: value } });
        }
    }
    setAndEmitColorbyId(colorbyId) {
        this.lastSelectedColorbyId = colorbyId;
        Khonsole.log(`setAndEmitColorbyId: colorbyId=[${colorbyId}]`);
        let clonedDataField = this.cloneDataFieldFromColorById(this.colorOptions, colorbyId);
        if (clonedDataField) {
            let matSelectChange = new MatSelectChange(null, clonedDataField);
            this.onApplyColorbyMatSelectChange.emit(matSelectChange);
        }
        else {
            Khonsole.error("No DataField for key [" + colorbyId + "]");
        }
    }
    onTableChange(event, triggerFocus = true) {
        // process the table value of the event
        let tableName = event.target.value; // e.g., 'gsva'
        if (this._config.database == "bulkrnaseqbrainumap" && tableName.toUpperCase() == "PATHWAYS") {
            tableName = "gsva";
        }
        if (this._config.database.startsWith("medulloblastoma") && tableName.toUpperCase() == "PATHWAYS") {
            tableName = "gsva";
        }
        Khonsole.log(`onTableChange: ${tableName}`);
        this.selectedTblIndex = this.tablesWithIds.findIndex(x => x == tableName);
        this.selectedTbl = tableName;
        if (triggerFocus) {
            let el = document.getElementById("colorbyTableTextControl");
            if (el) {
                // Empty last selection, if one existed.
                el.value = "";
                // Focus on the text field, so user can start typing.
                el.focus();
            }
        }
        this.setupFilteredOptions();
        // this.populateTableIds();
        this.cd.detectChanges();
    }
    onMetadataValueChange(event) {
        Khonsole.log(`onMetadataValueChange: Colorby value changed (source=metadata) to ${event.value.key}`);
        this.selectedOption = 'metadata';
        this.setAndEmitColorbyId(event.value.key);
    }
    onTableValueChange(event, collapseOnChange = true) {
        Khonsole.log(`onTableValueChange: Colorby value changed (source=table) to ${event.option.value}`);
        this.selectedOption = 'table';
        let tblName = this.tablesWithIds[this.selectedTblIndex];
        // clean up the value if needed
        let valtoEmit = event.option.value;
        if (valtoEmit.startsWith("ColorBy:") == false) {
            valtoEmit = `ColorBy:${tblName}=${valtoEmit}`;
        }
        // trigger the change (this will update the graph)
        this.setAndEmitColorbyId(valtoEmit);
        let el = document.getElementById('colorbyTableTextControl');
        el.select();
        if (collapseOnChange) {
            // collapse the mat-autocomplete panel
            this.autocompleteTrigger.closePanel();
        }
    }
    /**
     * When user presses enter on the colorby text field make it equivalent to clicking the option
     * @param event
     */
    onTablePressEnter(event) {
        if (document.querySelectorAll(".cdk-overlay-pane .mat-autocomplete-panel mat-option").length == 2) {
            let el = document.querySelector(".cdk-overlay-pane .mat-autocomplete-panel mat-option");
            el.click();
        }
    }
    populateFeatureItems() {
        this.featureColorOptions = this.colorOptions.filter(co => co.key != "ColorBy");
    }
    populateTableIds() {
        Khonsole.time("selectedTblIndex: populateTableIds");
        let self = this;
        self.tablesWithIds = [];
        Khonsole.log('selectedTblIndex: ==tbl ==> in populateTableIds');
        Khonsole.log('selectedTblIndex: ==> listing colorOptions');
        Khonsole.dir(this.colorOptions);
        let tablesToLoadIdsFrom = this.colorOptions.filter(co => co.key == "ColorBy" && co.label.startsWith("ColorBy:"))
            .map(co => co.tbl.replace(' ', '')); // turn "gistic threshold" into "gisticthreshold"
        Khonsole.log('selectedTblIndex: ==tbl Tables to load IDs from...');
        Khonsole.dir(tablesToLoadIdsFrom);
        if (tablesToLoadIdsFrom.length > 0) {
            let loaded_but_unsorted_table_ids = OncoData.instance.dataLoadedAction.datasetTableInfo.table_ids;
            if (loaded_but_unsorted_table_ids == null) {
                loaded_but_unsorted_table_ids = [];
            }
            // All table IDs are already in dataLoadedAction.
            if (loaded_but_unsorted_table_ids.length == tablesToLoadIdsFrom.length) {
                Khonsole.log('selectedTblIndex: All table IDs are already in dataLoadedAction.');
                // Sort loaded tables (from DLA) into order of tablesToLoadIdsFrom (from ColorOptions).
                let table_ids = tablesToLoadIdsFrom.map((loadTblName) => {
                    const matchingDLAtbl = loaded_but_unsorted_table_ids.find((DLAtbl) => DLAtbl.name === loadTblName);
                    return matchingDLAtbl;
                });
                tablesToLoadIdsFrom.forEach(table_name => {
                    let table_id_list = table_ids.find(x => x && (x.name.toLowerCase() == table_name.toLowerCase()));
                    if (table_id_list) {
                        Khonsole.log(`selectedTblIndex: ===== USED ${table_name}  table_id_list. ids=${table_id_list.ids.length}`);
                        self.tablesWithIds.push(table_name);
                        self.tableIdLists.push(table_id_list.ids);
                        self.data.tableName = table_name;
                        self.data.ids = table_id_list.ids;
                        // self.setupFilteredOptions();
                        // // NOTE: was not calling detectChanges here, but it seems to be needed.
                    }
                    else {
                        Khonsole.warn(`selectedTblIndex: Did not find TableIdList for table '${table_name}'.`);
                    }
                });
                Khonsole.log("selectedTblIndex: now call setupFilteredOptions");
                self.setupFilteredOptions();
                self.cd.detectChanges();
            }
            else {
                Khonsole.log('selectedTblIndex: Still need to load some table IDs not in dataLoadedAction.');
                // Load IDs from tables (slow!), and then save it to dataset table,
                // and update OncoData.instance.dataLoadedAction.datasetTableInfo.table_ids .
                let database = OncoData.instance.dataLoadedAction.dataset;
                self.tablesWithIds = [];
                self.tableIdLists = [];
                const db = new Dexie('notitia-' + database);
                for (let iTbl = 1; iTbl <= tablesToLoadIdsFrom.length; iTbl++) {
                    let table_name = tablesToLoadIdsFrom[iTbl - 1]; // TBD - jsut the first right now!
                    Khonsole.warn(`selectedTblIndex: ===tbl populate  tblIndexTouse = ${iTbl}th, [${table_name}]`);
                    try {
                        db.open().then(v => {
                            Khonsole.log("selectedTblIndex: ==tbl DB ready to get keys for colorby tbl " + table_name + ".");
                            Khonsole.time("selectedTblIndex: ==tbl loadIds_" + table_name);
                            // v._allTables["cna"].toCollection().keys()
                            v.table(table_name).toCollection().keys(function (identifiers) {
                                self.tablesWithIds.push(table_name);
                                self.tableIdLists.push(identifiers);
                                Khonsole.timeEnd("==tbl loadIds_" + table_name);
                                Khonsole.log(`==tbl Retrieved ${identifiers.length} IDs in table ${table_name}.`);
                                if (self.tablesWithIds.length == tablesToLoadIdsFrom.length) {
                                    // All database loading is done, so save ID lists.
                                    Khonsole.log("selectedTblIndex: ALL DB IDs LOADED.");
                                    // DUPLICATE CODE FROM ABOVE, WHERE WE CHECKED IF ALL TABLES WERE ALREADY LOADED.
                                    // BUT MODIFIED
                                    // ==========================================================
                                    // Sort loaded tables (from DLA) into order of tablesToLoadIdsFrom (from ColorOptions).
                                    let copy_of_table_ids = [...self.tableIdLists];
                                    // loaded_but_unsorted_table_ids = self.tablesWithIds.map((loadTblName) => {
                                    //   let ti: TableIdList = new TableIdList(loadTblName, loadTblName, []);
                                    //   ti.ids = copy_of_table_ids[tablesToLoadIdsFrom.indexOf(loadTblName)];
                                    //   return ti
                                    // });
                                    loaded_but_unsorted_table_ids = self.tablesWithIds.map((loadTblName, i) => {
                                        let ti = new TableIdList(loadTblName, loadTblName, []);
                                        ti.ids = copy_of_table_ids[i];
                                        return ti;
                                    });
                                    let table_ids = tablesToLoadIdsFrom.map((loadTblName) => {
                                        const matchingDLAtbl = loaded_but_unsorted_table_ids.find((DLAtbl) => DLAtbl.name === loadTblName);
                                        return matchingDLAtbl;
                                    });
                                    // clear out so we canwrite the sorted version
                                    self.tablesWithIds = [];
                                    self.tableIdLists = [];
                                    tablesToLoadIdsFrom.forEach(table_name => {
                                        let table_id_list = table_ids.find(x => x && (x.name.toLowerCase() == table_name.toLowerCase()));
                                        if (table_id_list) {
                                            Khonsole.log(`selectedTblIndex: ===== USED ${table_name}  table_id_list. ids=${table_id_list.ids.length}`);
                                            self.tablesWithIds.push(table_name);
                                            self.tableIdLists.push(table_id_list.ids);
                                            // self.setupFilteredOptions();
                                            // // NOTE: was not calling detectChanges here, but it seems to be needed.
                                        }
                                        else {
                                            Khonsole.warn(`selectedTblIndex: Did not find TableIdList for table '${table_name}'.`);
                                        }
                                    });
                                    self.data.tableName = self.tableIdLists[0].name;
                                    self.data.ids = self.tableIdLists[0].ids;
                                    let new_table_id_lists = []; // !!!!!!!!!!!!!!!!!!!!!!!!!
                                    self.tablesWithIds.forEach(function (tbl_name, i) {
                                        let table_id_list = new TableIdList(tbl_name, tbl_name, self.tableIdLists[i]);
                                        new_table_id_lists.push(table_id_list);
                                    });
                                    let dla = OncoData.instance.dataLoadedAction;
                                    dla.datasetTableInfo.table_ids = new_table_id_lists;
                                    OncoData.instance.dataLoadedAction.datasetTableInfo.table_ids = new_table_id_lists;
                                    self.datasetService.getDataset(dla.dataset).then(dataset_result => {
                                        let dataset = dataset_result;
                                        if (dataset == null) {
                                            throw ("Dataset null in populateTableIds");
                                        }
                                        dataset.table_ids = new_table_id_lists;
                                        DatasetService.db = new Dexie('notitia-' + dla.dataset);
                                        return DatasetService.db.open().then(v => {
                                            return DatasetService.db
                                                .table('dataset')
                                                .clear()
                                                .then(sdfd => {
                                                Khonsole.log("selectedTblIndex: == Updating table IDs");
                                                Khonsole.log("selectedTlIndex: All table IDs loaded. Set up filtered options.");
                                                window.setTimeout(() => {
                                                    self.setupFilteredOptions();
                                                    self.cd.detectChanges();
                                                }, 50);
                                                return DatasetService.db
                                                    .table('dataset')
                                                    .add(dataset);
                                            });
                                        });
                                    });
                                }
                                // self.data.tableName = table_name;
                                // self.data.ids = identifiers;
                                // self.setupFilteredOptions();
                                // self.cd.detectChanges();
                            });
                        });
                    }
                    catch (ex) {
                        Khonsole.warn("selectedTblIndex: ==tbl EXCEPTION getting keys from [" + table_name + ']');
                        Khonsole.dir(ex);
                    }
                }
            }
            Khonsole.log("selectedTblIndex: ==tbl tablesToLoad For loop done.");
        }
        else {
            // No tables available
            self.data.tableName = this.unset_table_placeholder;
            self.data.ids = [];
        }
        Khonsole.timeEnd("populateTableIds");
        Khonsole.time("populateTableIds-detectChanges");
        // self.cd.detectChanges();
        Khonsole.timeEnd("populateTableIds-detectChanges");
    }
    setupFilteredOptions() {
        Khonsole.time("setupFilteredOptions");
        this.data.ids = this.tableIdLists[this.selectedTblIndex];
        this.data.tableName = this.selectedTbl || this.tablesWithIds[this.selectedTblIndex];
        Khonsole.log("GG=", `setupFilteredOptions: selectedTblIndex=[${this.selectedTblIndex}], selectedTbl=${this.selectedTbl}`);
        // moved setup of filteredOptions into constructor, like in dialog-colorby
        this.copyOfIds = [...this.data.ids];
        this.filteredOptions = this.colorbyControl.valueChanges
            .pipe(startWith(''), map(id => id ? this.filterIds(id) : this.copyOfIds.slice(0, 1000)));
        Khonsole.timeEnd("setupFilteredOptions");
    }
    ngOnChanges(changes) {
        Khonsole.log("ngOnChanges in colorbyForm.");
        if (changes["colorOptions"]) {
            Khonsole.warn("Use colorOptions to populate table Ids...");
            this.populateFeatureItems();
            this.populateTableIds();
        }
    }
    ngOnDestroy() {
        Khonsole.warn("ColorbyForm OnDestroy");
    }
    filterIds(enteredText) {
        Khonsole.time("filterIds");
        Khonsole.warn(`=select filterIds, selecteTbl=[${this.selectedTblIndex != null ? this.selectedTblIndex : 'NULL'}]`);
        let upperEnteredText = enteredText.toUpperCase();
        let result = this.copyOfIds.filter(state => state.toUpperCase().indexOf(upperEnteredText) >= 0);
        result = result.slice(0, 1000);
        Khonsole.timeEnd("filterIds");
        return result;
    }
    byKey(p1, p2) {
        if (p2 == null) {
            return false;
        }
        try {
            return p1.label === p2.label;
        }
        catch (ex) {
            Khonsole.error("byKey failure in graph panel vis. p1 and p2.");
        }
    }
}
