/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./legend-eye.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "./legend-eye.component";
var styles_LegendEyeComponent = [i0.styles];
var RenderType_LegendEyeComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LegendEyeComponent, data: {} });
export { RenderType_LegendEyeComponent as RenderType_LegendEyeComponent };
export function View_LegendEyeComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co._onClick($event, _co.legend, _co.i) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "i", [["class", "eye"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "eye"; var currVal_1 = (_co.visible ? "fa fa-eye" : "fa fa-eye-slash"); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = (_co.visible ? "fa fa-eye" : "fa fa-eye-slash"); var currVal_3 = "eye"; _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
export function View_LegendEyeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workspace-legend-eye", [], null, null, null, View_LegendEyeComponent_0, RenderType_LegendEyeComponent)), i1.ɵdid(1, 114688, null, 0, i5.LegendEyeComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LegendEyeComponentNgFactory = i1.ɵccf("app-workspace-legend-eye", i5.LegendEyeComponent, View_LegendEyeComponent_Host_0, { legend: "legend", i: "i" }, { onClick: "onClick" }, []);
export { LegendEyeComponentNgFactory as LegendEyeComponentNgFactory };
