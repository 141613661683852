import { Khonsole } from "app/khonsole";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  EventEmitter,
} from "@angular/core";
import * as _ from "lodash";
import { Legend } from "../../../../model/legend.model";

@Component({
  selector: "app-workspace-legend-eye",
  templateUrl: "./legend-eye.component.html",
  styleUrls: ["./legend-eye.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LegendEyeComponent implements OnInit {
  public autoUpdate = true;
  visible: boolean = true;

  @Input() legend: Legend;
  @Input() i: number;

  @Output() onClick = new EventEmitter<any>();

  ngOnInit() {
    if (this.legend && this.legend.visibility) {
      try {
        this.visible = Boolean(this.legend.visibility[this.i]);
      } catch (err) {
        Khonsole.warn("CAUGHT: " + err);
      }
    }
  }

  _onClick(event, legend: Legend, i: number) {
    this.onClick.emit({ legend: legend, i: i, event: event });
  }

  public update(): void {
    if (!this.autoUpdate) {
      return;
    }
    try {
      this.cd.detectChanges();
    } catch (err) {
      Khonsole.error(
        `TEMPNOTE: error in legend update, probably bad _legends. ${err}`
      );
    }
  }

  constructor(public cd: ChangeDetectorRef) {}
}
