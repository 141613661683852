/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./legend-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/expansion/typings/index.ngfactory";
import * as i3 from "@angular/material/expansion";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/common";
import * as i6 from "@angular/flex-layout/extended";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "@angular/material/tooltip";
import * as i9 from "@angular/cdk/overlay";
import * as i10 from "@angular/cdk/scrolling";
import * as i11 from "@angular/cdk/platform";
import * as i12 from "@angular/cdk/bidi";
import * as i13 from "@angular/platform-browser";
import * as i14 from "./legend-eye/legend-eye.component.ngfactory";
import * as i15 from "./legend-eye/legend-eye.component";
import * as i16 from "@angular/cdk/collections";
import * as i17 from "@angular/platform-browser/animations";
import * as i18 from "./legend-panel.component";
var styles_LegendPanelComponent = [i0.styles];
var RenderType_LegendPanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LegendPanelComponent, data: {} });
export { RenderType_LegendPanelComponent as RenderType_LegendPanelComponent };
function View_LegendPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "border-bottom-width: 1px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "legend-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" Hidden: ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.countAttempt(_v.context.$implicit) + _v.context.$implicit.excludedValues.length); _ck(_v, 2, 0, currVal_0); }); }
function View_LegendPanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "mat-expansion-panel-header", [["class", "mat-expansion-panel-header"], ["role", "button"]], [[1, "id", 0], [1, "tabindex", 0], [1, "aria-controls", 0], [1, "aria-expanded", 0], [1, "aria-disabled", 0], [2, "mat-expanded", null], [2, "mat-expansion-toggle-indicator-after", null], [2, "mat-expansion-toggle-indicator-before", null], [40, "@.disabled", 0], [40, "@expansionHeight", 0]], [[null, "click"], [null, "keydown"], ["component", "@expansionHeight.start"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._keydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("component:@expansionHeight.start" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._animationStarted() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatExpansionPanelHeader_0, i2.RenderType_MatExpansionPanelHeader)), i1.ɵdid(1, 180224, null, 0, i3.MatExpansionPanelHeader, [i3.MatExpansionPanel, i1.ElementRef, i4.FocusMonitor, i1.ChangeDetectorRef, [2, i3.MAT_EXPANSION_PANEL_DEFAULT_OPTIONS]], null, null), i1.ɵpod(2, { collapsedHeight: 0, expandedHeight: 1 }), i1.ɵpod(3, { value: 0, params: 1 }), (_l()(), i1.ɵeld(4, 0, null, 0, 10, "mat-panel-title", [["class", "legend-header mat-expansion-panel-header-title"], ["style", "padding-top: 4px !important; padding-bottom: 4px !important"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "color-legend-header": 0 }), i1.ɵdid(8, 933888, null, 0, i6.DefaultClassDirective, [i1.ElementRef, i7.StyleUtils, i7.MediaMarshaller, i5.ɵNgClassImpl, [6, i5.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(9, { "color-legend-header": 0 }), i1.ɵdid(10, 16384, null, 0, i3.MatExpansionPanelTitle, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵeld(13, 16777216, null, null, 1, "i", [["class", "fa fa-info-circle"], ["matTooltipClass", "custom-tooltip"], ["matTooltipPosition", "right"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 14)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("mouseenter" === en)) {
        var pd_3 = (_co.displayTooltip(i1.ɵnov(_v, 14)) !== false);
        ad = (pd_3 && ad);
    } if (("mouseleave" === en)) {
        var pd_4 = (_co.hideTooltip(i1.ɵnov(_v, 14)) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 212992, [[2, 4], ["legendTooltip", 4]], 0, i8.MatTooltip, [i9.Overlay, i1.ElementRef, i10.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i11.Platform, i4.AriaDescriber, i4.FocusMonitor, i8.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i12.Directionality], [2, i8.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i13.HAMMER_LOADER]], { position: [0, "position"], disabled: [1, "disabled"], message: [2, "message"], tooltipClass: [3, "tooltipClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = "legend-header"; var currVal_11 = _ck(_v, 7, 0, _co.isColorLegend(_v.parent.context.$implicit)); _ck(_v, 6, 0, currVal_10, currVal_11); var currVal_12 = _ck(_v, 9, 0, _co.isColorLegend(_v.parent.context.$implicit)); var currVal_13 = "legend-header"; _ck(_v, 8, 0, currVal_12, currVal_13); var currVal_15 = "right"; var currVal_16 = true; var currVal_17 = i1.ɵinlineInterpolate(1, "", _co.smarterName(_v.parent.context.$implicit.name), ""); var currVal_18 = "custom-tooltip"; _ck(_v, 14, 0, currVal_15, currVal_16, currVal_17, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).panel._headerId; var currVal_1 = (i1.ɵnov(_v, 1).disabled ? (0 - 1) : 0); var currVal_2 = i1.ɵnov(_v, 1)._getPanelId(); var currVal_3 = i1.ɵnov(_v, 1)._isExpanded(); var currVal_4 = i1.ɵnov(_v, 1).panel.disabled; var currVal_5 = i1.ɵnov(_v, 1)._isExpanded(); var currVal_6 = (i1.ɵnov(_v, 1)._getTogglePosition() === "after"); var currVal_7 = (i1.ɵnov(_v, 1)._getTogglePosition() === "before"); var currVal_8 = i1.ɵnov(_v, 1)._animationsDisabled; var currVal_9 = _ck(_v, 3, 0, i1.ɵnov(_v, 1)._getExpandedState(), _ck(_v, 2, 0, i1.ɵnov(_v, 1).collapsedHeight, i1.ɵnov(_v, 1).expandedHeight)); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_14 = _co.smarterName(_v.parent.context.$implicit.name); _ck(_v, 12, 0, currVal_14); }); }
function View_LegendPanelComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "legend-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "swatch"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["\u00A0\u00A0\u00A0\u00A0", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.values[_v.context.index], ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 3, 0, currVal_1); }); }
function View_LegendPanelComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegendPanelComponent_5)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.labels; _ck(_v, 2, 0, currVal_0); }, null); }
function View_LegendPanelComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workspace-legend-eye", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onEyeClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_LegendEyeComponent_0, i14.RenderType_LegendEyeComponent)), i1.ɵdid(1, 114688, [[1, 4]], 0, i15.LegendEyeComponent, [i1.ChangeDetectorRef], { legend: [0, "legend"], i: [1, "i"] }, { onClick: "onClick" })], function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit; var currVal_1 = _v.parent.context.index; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_LegendPanelComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "legend-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegendPanelComponent_8)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "swatch"]], [[4, "background-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.customizeColor(_v.parent.parent.context.$implicit, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0\u00A0\u00A0\u00A0"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "clickable"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLegendItemTextClick(_v.parent.parent.context.$implicit, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { selected: 0 }), i1.ɵdid(9, 933888, null, 0, i6.DefaultClassDirective, [i1.ElementRef, i7.StyleUtils, i7.MediaMarshaller, i5.ɵNgClassImpl, [6, i5.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(10, { selected: 0 }), (_l()(), i1.ɵted(11, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.isScatterVisualization; _ck(_v, 2, 0, currVal_0); var currVal_3 = "clickable"; var currVal_4 = _ck(_v, 8, 0, _co.legendItemHasSelectedPoints(_v.parent.parent.context.$implicit, _v.context.index)); _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_5 = _ck(_v, 10, 0, _co.legendItemHasSelectedPoints(_v.parent.parent.context.$implicit, _v.context.index)); var currVal_6 = "clickable"; _ck(_v, 9, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.parent.parent.context.$implicit.values[_v.context.index]; _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "ocLegendItem_", _v.context.$implicit, ""); _ck(_v, 5, 0, currVal_2); var currVal_7 = _co.formattedLegendItemText(_v.parent.parent.context.$implicit, _v.context.index); _ck(_v, 11, 0, currVal_7); }); }
function View_LegendPanelComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegendPanelComponent_7)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.labels; _ck(_v, 2, 0, currVal_0); }, null); }
function View_LegendPanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 9, "mat-expansion-panel", [["class", "mat-expansion-panel"]], [[2, "mat-expanded", null], [2, "_mat-animation-noopable", null], [2, "mat-expansion-panel-spacing", null]], null, null, i2.View_MatExpansionPanel_0, i2.RenderType_MatExpansionPanel)), i1.ɵdid(1, 1753088, null, 1, i3.MatExpansionPanel, [[3, i3.MAT_ACCORDION], i1.ChangeDetectorRef, i16.UniqueSelectionDispatcher, i1.ViewContainerRef, i5.DOCUMENT, [2, i17.ANIMATION_MODULE_TYPE], [2, i3.MAT_EXPANSION_PANEL_DEFAULT_OPTIONS]], { expanded: [0, "expanded"] }, null), i1.ɵqud(603979776, 3, { _lazyContent: 0 }), i1.ɵprd(256, null, i3.MAT_ACCORDION, undefined, []), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_LegendPanelComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_LegendPanelComponent_4)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_LegendPanelComponent_6)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = true; _ck(_v, 1, 0, currVal_3); var currVal_4 = _co.isntDataPointsPlaceholder(_v.context.$implicit); _ck(_v, 5, 0, currVal_4); var currVal_5 = ((_v.context.$implicit.type === "SHAPE") && _co.isntDataPointsPlaceholder(_v.context.$implicit)); _ck(_v, 7, 0, currVal_5); var currVal_6 = (_v.context.$implicit.type === "COLOR"); _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).expanded; var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_2 = i1.ɵnov(_v, 1)._hasSpacing(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_LegendPanelComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { legendItems: 1 }), i1.ɵqud(671088640, 2, { legendTooltipsQuery: 1 }), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["style", "height: auto !important"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegendPanelComponent_1)), i1.ɵdid(4, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LegendPanelComponent_2)), i1.ɵdid(6, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._legendFilters; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.allLegendsExceptDataPointsPlaceholder(); var currVal_2 = _co.trackLegendByFn; _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_LegendPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workspace-legend-panel", [], null, null, null, View_LegendPanelComponent_0, RenderType_LegendPanelComponent)), i1.ɵdid(1, 4243456, null, 0, i18.LegendPanelComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var LegendPanelComponentNgFactory = i1.ɵccf("app-workspace-legend-panel", i18.LegendPanelComponent, View_LegendPanelComponent_Host_0, { config: "config", decorators: "decorators", legends: "legends", legendFilters: "legendFilters" }, {}, []);
export { LegendPanelComponentNgFactory as LegendPanelComponentNgFactory };
