<div class="common-widget" ><!-- widget -->
  <table border="0" width="100%"><tr>
    <td cdkDragHandle>
    <mat-icon (click)='toggleHidden()' class="widget-icon" *ngIf="getExpanded()">expand_less</mat-icon>
    <mat-icon (click)='toggleHidden()' class="widget-icon" *ngIf="!getExpanded()">expand_more</mat-icon>
    <!--</button>-->
    <b>{{ getName() }}</b>
    </td><td align="right"><mat-icon (click)='clickSettings()' class="widget-icon" >settings</mat-icon></td>
  </tr></table>
  <div [id]="getContainerName()"  #parentOfSvgDiv class="common-widget common-widget-svgdiv">
<div #cohortControls class="commonSideItem mat-form-field-onco-wide-panel">
  <form class="mat-form-field-onco-wide-panel">

    <mat-form-field class="mat-form-field-onco-wide-panel">
      <span class="legendHeader">Selected Cohort</span>
      <table width="100%" style="border-collapse: collapse; border: 1px solid lightgray;"><tr>
        <td id="colorCellForSelectedCohort" width="10" bgcolor="blue" #selectedCohortColorCell>&nbsp;</td>
        <td id="spacerColorCellForSelectedCohort"  >&nbsp;</td>
        <td width="100%" height="39.2"><input #selectedCohortTextField value="All Patients + Samples" matInput (keyup)="parentApplySelectedCohortNameFilter($event.target.value)" >
        </td>
        <td id="cellForAddingCohort" style="display:none;"><button (click)="parentAddCohortClicked($event)"  mat-button class="button-next-to-input"><mat-icon>add</mat-icon></button></td>
      </tr></table>
    </mat-form-field>

    <span class="legendHeader">Saved Cohorts</span>

    <div class="mat-form-field-onco-wide-panel">
      <table id="savedCohortsTable" width="100%" border="1" frame="hsides" rules="rows" style="border-collapse: collapse; border: 1px solid lightgray;">
        <tr>
        <td   width="10" bgcolor="lightgray" >&nbsp;</td>
        <td   >&nbsp;</td>
        <td width="100%"  >All Patients + Samples</td>
      <!--<td id="cellForAddingCohort" style="display:none;"><button (click)="parentAddCohortClicked($event)"  mat-button class="button-next-to-input"><mat-icon>add</mat-icon></button></td>-->
      </tr></table>
    </div> <!-- was </mat-form-field> -->

  </form>
</div>


<!--
    <md-expansion-panel  class="custom-header" hideToggle="true" #example>
      <md-expansion-panel-header>
        <span>
          Personal data
        </span>
        <md-panel-description>
          Type your name and age

        </md-panel-description>
      </md-expansion-panel-header>

      <md-form-field>
        <input mdInput placeholder="First name">
      </md-form-field>

      <md-form-field>
        <input mdInput placeholder="Age">
      </md-form-field>
    </md-expansion-panel> -->

  </div>
</div>
