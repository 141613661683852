<div class="panel no-application-bar">
  <div>
    <div style='letter-spacing: 14px;
    font-weight: 300;
    font-size: 2.5rem;
    color: #1e88e5;
    text-transform: uppercase;'>Oncoscape</div>
    <div style='font-size: 1rem; text-transform: uppercase; color: #666; letter-spacing: 5px; padding-top: 20px;'>Accelerate
      your research </div>
  </div>

  <div fxLayout.xs="column">
    <div fxFlex.sm='1 1 100%' fxFlex.md='1 1 100%' fxFlex.lg='1 1 100%' fxFlex.xl='1 1 100%'>
      <section>
        <div fxFlex.xs="50%" fxFlex="20%" class='landing-image-container' style='background: url(assets/landing/img-1.jpg) no-repeat; background-position: 20% 8%; -webkit-background-size: 100% auto;'></div>
        <div fxHide.xs fxFlex="20%" fxFlexOffset='20px' class='landing-image-container' style='background: url(assets/landing/img-4.jpg) no-repeat; background-position: 50% 50%; -webkit-background-size: 100% auto;'></div>
        <div fxHide.xs fxFlex="20%" fxFlexOffset='20px' class='landing-image-container' style='background: url(assets/landing/img-chromo.jpg) no-repeat; background-position: 0% 0%; -webkit-background-size: 100% auto;'></div>
        <div fxHide.xs fxFlex="20%" fxFlexOffset='20px' class='landing-image-container' style='background: url(assets/landing/img-genome.jpg) no-repeat; background-position: 0% 0%; -webkit-background-size: 150% auto;'></div>
        <div fxFlex.xs="50%" fxFlex="20%" fxFlexOffset='20px' class='landing-image-container' style='background: url(assets/landing/img-scatter.jpg) no-repeat; background-position: 20% 50%; -webkit-background-size: 150% auto;'></div>
      </section>
    </div>
  </div>

  <div fxLayout='row' fxLayout.sm='column' fxLayout.xs='column' fxLayoutAlign="space-between start"
    style='padding-bottom: 30px;'>
    <div>

      <button mat-raised-button color='primary' style='line-height: 40px;' (click)="getStartedClick()"
        class='button landing-button'>Get Started
      </button>

    </div>
    <div>
      <span ngClass.xs="xs-button" ngClass.sm="xs-button " fxFlexOffset.gt-sm='40px' class='landing-span'>Maximize
        the potential of your clinical and genomic data by combining it with best-in-class
        analysis, reference data
        sets and domain-specific visualizations. To request training or a custom solution contact
        us at
        <a style='color: #1e88e5; text-decoration: none;' href='mailto:contact@oncoscape.org'>contact@oncoscape.org</a>.
      </span>
    </div>
  </div>

  <div class='landing-headers' style='padding-top:20px;'></div>
  <div class='landing-section-title'>Analysis</div>
  <section fxLayout.xs='column'>
    <div fxFlex.sm='1 1 100%' fxFlex.md='1 1 100%' fxFlex.lg='1 1 50%' fxFlex.xl='1 1 50%' class='landing-copy '>
      <div class='tool tool-opensource'></div>
      <span class=' landing-copy-title '>Reliable</span>
      <span class='landing-copy-desc '>All data manipulation and augmentation is done using
        established open source libraries and public stores with citations.
      </span>
    </div>

    <div fxFlex.sm='1 1 100% ' fxFlex.md='1 1 100% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% '
      class='landing-copy '>
      <div class='tool tool-reproducible '></div>
      <span class='landing-copy-title '>Reproducible</span>
      <span class='landing-copy-desc '>All analysis in Oncoscape is fully reproducible. Pause,
        rewind and interrogate every variable and result.</span>
    </div>
  </section>
  <div class='landing-headers '></div>
  <div class='landing-section-title '>Data</div>
  <section fxLayout.xs='column '>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy '>
      <div class='tool tool-tcga '></div>
      <span class='landing-copy-title '>Public Data Sets</span>
      <span class='landing-copy-desc '>Explore The Cancer Genome Atlas (TCGA) and other curated
        data from our growing list of reference libraries.</span>
    </div>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy '>
      <div class='tool tool-datahub '></div>
      <span class='landing-copy-title '>Private Data Upload</span>
      <span class='landing-copy-desc '>
        Securely manage and analyze your clinical and genomics data, then share results with
        colleagues.
      </span>
    </div>
  </section>
  <section fxLayout.xs='column '>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy '>
      <div class='tool tool-genesets2 '></div>

      <span class='landing-copy-title '>Gene Sets</span>
      <span class='landing-copy-desc '>Select from thousands of curated gene sets from MSigDB or
        create your own.</span>
    </div>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy '>
      <div class='tool tool-pathway-commons '></div>
      <span class='landing-copy-title '>Pathways</span>
      <span class='landing-copy-desc '>Select from thousands of top-level pathways from Reactome,
        PathwayCommons, and more. </span>
    </div>
  </section>
  <div class='landing-headers '></div>
  <div class='landing-section-title '>Visualization</div>
  <section fxLayout.xs='column '>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy
        '
      class='landing-copy '>
      <div class='tool tool-dashboard '></div>
      <span class='landing-copy-title '>Clinical Dashboard</span>
      <span class='landing-copy-desc '>Compare cohorts at a glance</span>
    </div>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy
        '
      class='landing-copy '>
      <div class='tool tool-spreadsheet'></div>
      <span class='landing-copy-title '>Spreadsheet</span>
      <span class='landing-copy-desc '>Sort and export your data, cohorts and genes of interest in
        tabular format.</span>
    </div>
  </section>
  <section fxLayout.xs='column '>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy
        '
      class='landing-copy '>
      <div class='tool tool-decomp'></div>
      <span class='landing-copy-title '>Matrix Decomposition</span>
      <span class='landing-copy-desc '>Visualize the inherent spatial structure of high dimensional
        datasets in 3D. With 13 fully customizable linear dimension
        reduction algorithm, select from 5 variants of PCA, Factor Analysis, LDA, SVD and more.</span>
    </div>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy
        '
      class='landing-copy '>
      <div class='tool tool-manifoldlearning'></div>
      <span class='landing-copy-title '>Manifold Learning</span>
      <span class='landing-copy-desc '>Like Matrix Decomposition, Manifold Learning seeks to unveil
        the spatial structure of high dimensional datasets. Unlike
        Matrix Decomposition the dimension reduction process is non-linear. Available methods
        include T-SNE, MDS, UMAP, IsoMap,
        Local Linear and Spectral Embedding.</span>
    </div>
  </section>
  <section fxLayout.xs='column '>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy
        '
      class='landing-copy '>
      <div class='tool tool-heatmap3'></div>
      <span class='landing-copy-title '>Heat map</span>
      <span class='landing-copy-desc '>Sort, group and visualize your data using 7 agglomerative
        clustering algorithms in conjunction with 20 distance metrics.
        Leverage dendrogram to sub select populations of samples or markers for further
        investigation.</span>
    </div>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy '>
      <div class='tool tool-genome'></div>
      <span class='landing-copy-title '>Genome</span>
      <span class='landing-copy-desc '>Identify chromosomal regions of interest by projecting your
        data onto a visual representation of the genome. Overlay
        CNV, Gistic or expression data along with Topically Aligned Domains and Cytobands. Explore
        thousands of curated gene
        sets or create your own.</span>
    </div>
  </section>
  <section fxLayout.xs='column '>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy '>
      <div class='tool tool-chromosome'></div>
      <span class='landing-copy-title '>Chromosome</span>
      <span class='landing-copy-desc '>Compare datasets at the chromosomal level with circos and
        linear visualizations. Overlay pathways or topically aligned
        domains.
      </span>
    </div>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy '>
      <div class='tool tool-pathways-tool'></div>
      <span class='landing-copy-title '>Pathways</span>
      <span class='landing-copy-desc '>Overlay your data on over 5,900 pathways curated from Kegg,
        Panther, Reactome, SMPDB, WikiPathways and Pathway Commons.
      </span>
    </div>
  </section>
  <section fxLayout.xs='column '>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy '>
      <div class='tool tool-survival'></div>
      <span class='landing-copy-title '>Survival</span>
      <span class='landing-copy-desc '>Estimate and compare survival rates for patient populations
        using Kaplin Kaplan-Meier, Nelson-Aalen, Aalen, Weibull
        and Cox models.
      </span>
    </div>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy '>
      <div class='tool tool-hazard'></div>
      <span class='landing-copy-title '>Hazard</span>
      <span class='landing-copy-desc '>Estimate and compare hazard rates for patient populations
        using Kaplin Kaplan-Meier, Nelson-Aalen, Aalen, Weibull and
        Cox models.
      </span>
    </div>
  </section>
  <section fxLayout.xs='column '>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy '>
      <div class='tool tool-timelines'></div>
      <span class='landing-copy-title '>Timelines</span>
      <span class='landing-copy-desc '>Visualize patterns in time series data with an interactive
        swimmer plot. Sort, group and highlight patients using clinical
        attributes.
      </span>
    </div>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy '>
      <div class='tool tool-box-whiskers'></div>
      <span class='landing-copy-title '>Box + Whisker</span>
      <span class='landing-copy-desc '>Gain insights into the distribution of your data by
        visualizing the minimum, first quartile, median, 3rd quartile and
        maximum values.</span>
    </div>
  </section>
  <section fxLayout.xs='column '>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy '>
      <div class='tool tool-forcedirected'></div>
      <span class='landing-copy-title '>Force Directed Graph</span>
      <span class='landing-copy-desc '>Visualize graph data in three-dimensions using a class of
        algorithms called " force directed graphs. " These algorithms
        use the number and strength of connections to position nodes.</span>
    </div>
    <div fxFlex.sm='1 1 50% ' fxFlex.md='1 1 50% ' fxFlex.lg='1 1 50% ' fxFlex.xl='1 1 50% ' class='landing-copy '>
      <div class='tool tool-edges'></div>
      <span class='landing-copy-title '>Edges</span>
      <span class='landing-copy-desc '>Connect multiple graphs with edges. Link tumor/normal
        samples or visualize mutations.</span>
    </div>
  </section>



  <div class='landing-headers '></div>
  <section fxLayout.xs='column ' style='padding-bottom:60px;padding-top:20px; '>
    <div fxFlex.sm='1 1 100% ' fxFlex.md='1 1 100% ' fxFlex.lg='1 1 100% ' fxFlex.xl='1 1 50% '
      class='landing-copy
        ' class='landing-copy '>
      <div class='landing-section-title '>Overview</div>
      <iframe style='border: 1px solid #9e9e9e; ' id="ytplayer " type="text/html " width="720 "
        height="405 " src="https://www.youtube.com/embed/VfKFbri7XfQ?loop=1&modestbranding=1&playsinline=1&rel=0&showinfo=0&color=white "
        frameborder="0 " allowfullscreen></iframe>


    </div>
<!--    <div fxFlex.sm='1 1 100% ' fxFlex.md='1 1 100% ' fxFlex.lg='1 1 100% ' fxFlex.xl='1 1 50% '
      class='landing-copy
        ' class='landing-copy '>
      <div class='landing-section-title '>News</div>
      <div id="twitter-widget-holder " style='border: 1px solid #9e9e9e;max-height: 405px;overflow-y: scroll;'>
        <a class="twitter-timeline " data-theme="light " data-link-color="#1e88e5 " href="https://twitter.com/oncoscape?ref_src=twsrc%5Etfw ">Tweets
          by oncoscape</a>
      </div>
    </div> -->
  </section>


  <footer class='landing-footer '>
    <div fxLayout='row ' fxLayout.sm='column ' fxLayout.xs='column ' fxLayoutAlign="space-between center ">
      <div class="footer-text ">
        Fred Hutch 1100 Fairview Ave. N.
        <br /> P.O. Box 19024, Seattle, WA 98109
        <br />
        <a style='color:#fff; text-decoration: none;' href="/assets/terms.html">Terms and Conditions</a>
      </div>
      <div>
        <div class='section-footer '>
          <span class='footer-text '>
            <a href=" https://twitter.com/oncoscape " target="_blank ">
              <i class="fa fa-twitter-square fa-3x social "></i>
            </a>
            <a href="https://www.instagram.com/oncoscape/ " target="_blank ">
              <i class="fa fa-instagram fa-3x social "></i>
            </a>
            <a href="https://www.facebook.com/STTRCancer/ " target="_blank ">
              <i class="fa fa-facebook-square fa-3x social "></i>
            </a>
            <a href="mailto:contact@oncoscape.org" target="_blank ">
              <i class="fa fa-envelope-square fa-3x social "></i>
            </a>
          </span>
        </div>
      </div>
    </div>
  </footer>
</div>
