import { Khonsole } from 'app/khonsole';
import * as THREE from 'three';
import { EventEmitter } from '@angular/core';
import { WorkspaceComponent } from 'app/component/workspace/workspace.component';
import { DataField } from './../../model/data-field.model';
import { ScatterSelectionLassoController } from './../../controller/scatter/scatter.selection.lasso.controller';
import { AbstractScatterSelectionController } from './../../controller/scatter/abstract.scatter.selection.controller';
import { ChartScene } from './../workspace/chart/chart.scene';
import { ChartFactory } from 'app/component/workspace/chart/chart.factory';
import { GraphData } from 'app/model/graph-data.model';
import { Subscription } from 'rxjs';
import { Vector3, Color } from 'three';
import { LabelController, LabelOptions } from '../../controller/label/label.controller';
import { ChartObjectInterface } from '../../model/chart.object.interface';
import { DataDecorator, DataDecoratorTypeEnum, DataDecoratorValue, LegendFilter, CustomPalette } from '../../model/data-map.model';
import { EntityTypeEnum, DirtyEnum, LegendOptionEnum } from '../../model/enum.model';
import { ChartEvents } from '../workspace/chart/chart.events';
import { ChartSelection } from './../../model/chart-selection.model';
import { VisualizationView } from './../../model/chart-view.model';
import { GraphConfig } from './../../model/graph-config.model';
import { AbstractVisualization } from './visualization.abstract.component';
import { CommonSidePanelComponent } from '../workspace/common-side-panel/common-side-panel.component';
import { OncoData } from 'app/oncoData';
import { EdgesGraph } from './edges/edges.graph';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Legend } from  './../../model/legend.model';
import { DataService } from 'app/service/data.service';
import { DatasetService } from 'app/service/dataset.service';
import { HasLegendOptions, LegendOptionsProcessor } from './visualization.hasLegendOptions';

const fragShader = require('raw-loader!glslify-loader!app/glsl/scatter.frag');
const vertShader = require('raw-loader!glslify-loader!app/glsl/scatter.vert');
const vertShaderNoAttenuation = require('raw-loader!glslify-loader!app/glsl/scatterNoAttenuation.vert');
declare var $;
//declare var THREE;
/*
circle
blast
*/

export class SelectionModifiers {
  extend:boolean = false;
  inverse:boolean = false;
  constructor() {}
}

export class DataAdjustmentsFor2D {
  needsAdjustment:boolean = false;
  ZValuesFor2D: any[]; // (number | { row: any, index: any })[] = [];
}

export class AbstractScatterVisualization extends AbstractVisualization implements HasLegendOptions {
  public static textureImages = [
    './assets/shapes/shape-circle-solid-border.png', // added border
    './assets/shapes/shape-blast-solid.png',
    './assets/shapes/shape-diamond-solid.png',
    './assets/shapes/shape-polygon-solid.png',
    './assets/shapes/shape-square-solid.png',
    './assets/shapes/shape-star-solid.png',
    './assets/shapes/shape-triangle-solid.png',
    './assets/shapes/shape-na-solid.png'
  ];

  public isBasedOnAbstractScatter = true; // A quick test for this or descendants.

  public legendOptionsProcessor: LegendOptionsProcessor = new LegendOptionsProcessor();
  constructor() {
    super();
    this.legendOptionsProcessor.registerHandler(LegendOptionEnum.MARKER_BASE_SIZE, this.updateMarkerBaseSize.bind(this));
    this.legendOptionsProcessor.registerHandler(LegendOptionEnum.MARKER_OPACITY, this.updateMarkerOpacity.bind(this));
  }

  // #region More Legend Options update handlers
  updateMarkerBaseSize(baseSize:number) {
    this.pointsMaterial.uniforms.uMarkerBaseSize.value = baseSize;
    setTimeout(ChartScene.instance.render, 10);
  }

  updateMarkerOpacity(opacity: number) {
    this.pointsMaterial.uniforms.uMarkerOpacity.value = opacity;
    setTimeout(ChartScene.instance.render, 10);
  }
  // #endregion

  public getDataItemCount () {
    if (this.entity == EntityTypeEnum.SAMPLE) {
      return this.data.sid.length;
    } else {
      if (this.entity == EntityTypeEnum.PATIENT) {
        return this.data.pid.length;
      } else {
        if (this.entity == EntityTypeEnum.GENE) {
          return this.data.mid.length;
        } else {
          alert('failed ');
          return 0;
        }
      }
    }
  }

  public set data(data: GraphData) {
    this._data = data;
  }
  public get data(): GraphData {
    return this._data;
  }
  public set config(config: GraphConfig) {
    this._config = config;
  }
  public get config(): GraphConfig {
    return this._config;
  }

  getConfig(): GraphConfig {
    return this.config;
  }

  // Objects
  public selectionController: AbstractScatterSelectionController;
  private selectSubscription: Subscription;
  private pointsMaterial: THREE.ShaderMaterial;
  private pointsGeometry = new THREE.BufferGeometry();
  private points: THREE.Points;
  private positionsFrame: Number;
  private positionsPrev: Float32Array;
  private positions: Float32Array;
  private colors: Float32Array;
  // private alphas: Float32Array;
  private shapes: Float32Array;
  private sizes: Float32Array;
  private selected: Float32Array;
  private ids: Array<string>;
  private lbls: Array<string>;
  private lines: Array<THREE.Line>;


  private defaultPointColorR:number = 0.12;
  private defaultPointColorG:number = 0.53;
  private defaultPointColorB:number = 0.9;

  public getTargets(): {
    point: Vector3;
    id: string;
    idType: EntityTypeEnum;
  }[] {
    const p = this.points;
    const positions = this.points.geometry['attributes'].position.array;
    const pts = new Array<{
      point: Vector3;
      id: string;
      idType: EntityTypeEnum;
    }>(positions.length / 3);
    for (let i = 0; i < positions.length; i += 3) {
      pts[i / 3] = {
        point: new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2]),
        id: this.ids[i / 3],
        idType: this.config.entity
      };
    }
    return pts;
  }

  public getGVisibility() {
    return this.pointsGeometry.attributes.gVisibility;
  }

  private _lastSelectionPatientIds:Array<string> = [];

  public getLastSelectionPatientIds(){
    return this._lastSelectionPatientIds;
  }

  public removeIntersectFromSelection(d) {
    let self = this;
    Khonsole.log('in removeIntersectFromSelection');
    //let id = d.index / 3;
    let sampleId = this.ids[d.index];
    Khonsole.log('removeIntersectFromSelection id = ' + sampleId);
    let pid = OncoData.instance.currentCommonSidePanel.commonSidePanelModel.sampleMap[sampleId];
    let newSelectionPids  = this._lastSelectionPatientIds.filter(v =>  v != pid)
    this._lastSelectionPatientIds = newSelectionPids;
    let source = 'Selection';
    this.selectionController.highlightIndexes.delete(d.index * 3);
    this.recalculateLegendTotals(); // Needed here?

    const gSel = this.pointsGeometry.attributes.gSelected;
    gSel.setX(d.index, 0);
    self.pointsGeometry.attributes.gSelected.needsUpdate = true;
    ChartScene.instance.render();

    window.setTimeout(() => self.signalCommonSidePanel(self._lastSelectionPatientIds, source, EntityTypeEnum.SAMPLE, self._config), 50);
  }


  // Temporary kludge until LegendFilters really work.
  public previousPointVisibilities: Float32Array = new Float32Array(0);

  public addLegendFilter(legendFilter: LegendFilter){
      let foo = this.pointsGeometry.attributes.gVisibility;
      Khonsole.log('foo');
      Khonsole.dir(foo);
      let thePoints:Float32Array = foo.array as Float32Array;
      // foo.array is float32Array of everyone

      this.previousPointVisibilities =  thePoints;

  }

  setVisibilityBasedOnLegends(config: GraphConfig, decorators: DataDecorator[]) {
    let self=this;
    let visibilityLevels:Float32Array = new Float32Array(this.ids.length);
    if(self.previousPointVisibilities.length == this.ids.length){
      // We already filtered on something, use it a starting point.
      self.ids.forEach((id, index) => {
        visibilityLevels[index] = self.previousPointVisibilities[index];
      });
    } else {
      self.ids.forEach((id, index) => {
        visibilityLevels[index] = 1.0;
      });
    }
    self.pointsGeometry.setAttribute('gVisibility', new THREE.BufferAttribute(visibilityLevels, 1));

    // For each decorator, hide points if visibility in legend is 0.
    decorators.forEach(decorator => {
      if(decorator.legend && decorator.legend.visibility) {

        decorator.legend.visibility.map((legendItemVisibility, legendItemIndex) => {
          if(legendItemVisibility < 0.5){
            // visibilityLevels:Float32Array = new Float32Array(this.ids.lengt
            let pidsToHide = decorator.pidsByLabel[legendItemIndex].pids;
            if(pidsToHide == null) {
              // ? Why would it be null ?   Why do we have legend items with no correspodnign pids?
              Khonsole.warn('pidsToHide is null.');
            } else {
              pidsToHide.map((pid, pidIndex) => {
                let sid = OncoData.instance.currentCommonSidePanel.commonSidePanelModel.patientMap[pid];
                if(sid) {
                  let scatterIdIndex = self.ids.findIndex(v => v === sid);
                  visibilityLevels[scatterIdIndex] = 0;
                }
              })
            }
          }
        });

        self.pointsGeometry.setAttribute('gVisibility', new THREE.BufferAttribute(visibilityLevels, 1));
        self.pointsGeometry.attributes.gVisibility.needsUpdate = true;

      }
    });
  }


  public removeInvisiblesFromSelection(config: GraphConfig, decorators: DataDecorator[]) {
    let self = this;
    Khonsole.log('in removeInvisiblesFromSelection ###');
    this.setVisibilityBasedOnLegends(config, decorators);

    // Create an updated selection (without invisibles) and emit it.
    let source = 'Selection';
    let newHighlightIndexArray = Array.from(this.selectionController.highlightIndexes);// .delete(d.index * 3);

    let gSel = self.pointsGeometry.attributes.gSelected;
    let gVis = self.pointsGeometry.attributes.gVisibility;
    let newSelectionIds: Array<string> = [];
    newHighlightIndexArray.map(v => {
      let pointIndex = v/3;
      if (gVis.array[pointIndex] > 0.5) {
        //newHighlightIndexSet.add(v*3);
        newSelectionIds.push(self.ids[pointIndex]);
      } else {
        gSel.setX(pointIndex, 0);
      }
    })

    this._lastSelectionPatientIds = newSelectionIds; //ids.map(v => self._data.pid[v/3]);


    this.recalculateLegendTotals(); // Needed here?
    self.pointsGeometry.attributes.gSelected.needsUpdate = true;
    ChartScene.instance.render();

    window.setTimeout(() => self.signalCommonSidePanel(this._lastSelectionPatientIds, source, EntityTypeEnum.SAMPLE, self._config), 50);
  }

  public notifyEdgeGraphOfSelectionChange(weKnowNothingIsInSelection:boolean) {
      let edgesGraph = (ChartScene.instance.views[2].chart as EdgesGraph);
      if(edgesGraph){
        edgesGraph.softRequestLinkRegen();
      }
  }

  public regenLinks(){

  }

  create(entity:EntityTypeEnum, labels: HTMLElement, events: ChartEvents, view: VisualizationView): ChartObjectInterface {
    super.create(entity, labels, events, view);
    let self = this;
    this.selectionController = new ScatterSelectionLassoController(this.entity, view, events);
    this.selectionController.enable = true;
    this.selectSubscription = this.selectionController.onSelect.subscribe((data) => {
      let ids: Array<number> = data.ids; // ids are 3 times bigger than real index. We'll divide by 3.
      let source: any = data.source; // we EXPECT this is always "Selection", not "Cohort".
      const values: Array<DataDecoratorValue> = ids
        .map(v => v / 3)
        .map(v => {
          return {
            pid: this._data.pid[v],
            sid: this._data.sid[v],
            mid: null,
            key: EntityTypeEnum.SAMPLE,
            value: true,
            label: ''
          };
        });
      const dataDecorator: DataDecorator = {
        type: DataDecoratorTypeEnum.SELECT,
        values: values,
        field: null,
        legend: null,
        pidsByLabel: null,
        config: this._config
      };
      WorkspaceComponent.addDecorator(this._config, dataDecorator);

      this._lastSelectionPatientIds = ids.map(v => self._data.pid[v / 3]);
      this.recalculateLegendTotals();

      // If visualization in both graphs is scatter, then call addDecorator (for SELECT) for the other graph here.
      if (ChartScene.viewSelectionsShouldSync()) {
        let otherDataDecorator: DataDecorator = JSON.parse(JSON.stringify(dataDecorator));
        let otherGraph = this._config.graph == 1 ? 2 : 1;
        let otherConfig: GraphConfig = ChartScene.instance.views[otherGraph-1].chart.getConfig(); //.isScatterVisualization
        otherDataDecorator.config = otherConfig;
        window.setTimeout(() => {
          WorkspaceComponent.addDecorator(otherConfig, otherDataDecorator);
          Khonsole.log("Added datadec copy to other graph . " + otherConfig.graph);
          window.setTimeout(() => self.signalCommonSidePanel(this._lastSelectionPatientIds, source, EntityTypeEnum.SAMPLE, self._config), 200);
        }, 100);
      } else {
        window.setTimeout(() => self.signalCommonSidePanel(this._lastSelectionPatientIds, source, EntityTypeEnum.SAMPLE, self._config), 50);
      }
    });


    return this;
  }



  recalculateLegendTotals() {
    // Update decorators[x].legend.counts, based on _lastSelectionPatientIds.
    let self = this;
    try {
      this.decorators.forEach(dec => {
        if (dec.type == DataDecoratorTypeEnum.COLOR) { //} || dec.type == DataDecoratorTypeEnum.SHAPE ) {

          let decorator = dec;

          DataService.instance.LegendCountTotalsAndCollectPidsForDataDecorator(decorator);

          ///region
          // // Total up the counts for each label.
          // let valueCounts = {};
          // decorator.values.forEach(v => {
          //   // Count this value if a) nothing is selected, or b) this item is selected.
          //   if(self._lastSelectionPatientIds.length == 0 || self._lastSelectionPatientIds.includes(v.pid))
          //   {
          //     let hexColor:string = v.value.toString();
          //     if (hexColor.startsWith('#') == false){
          //       hexColor = "#"+("00000"+parseInt(v.value).toString(16)).slice(-6).toLowerCase();
          //     }

          //     if(valueCounts[hexColor] == null) {
          //       valueCounts[hexColor] = 0;
          //     }
          //     valueCounts[hexColor]++;
          //   }
          // });
          // let valueCountsArray: Array<number> = [];
          // decorator.legend.values.forEach( value => {
          //   let counts = valueCounts[value.toLowerCase()];
          //   if(counts ==false) { counts = 0;}
          //   valueCountsArray.push(counts);
          // });
          // decorator.legend.counts = valueCountsArray;
          ///endregion

        }
      });
    } catch (ex){
      Khonsole.error(ex);
    }
  }
  public signalCommonSidePanel(patientIdsForCommonSurvival, selectionSource, entityType:EntityTypeEnum, graphConfig:GraphConfig) {
    Khonsole.warn(`Graph ${graphConfig.graph} is signaling CSPl`)
    if (selectionSource == "Legend") {
      return;
    }

    if (OncoData.instance.currentCommonSidePanel){
      if(entityType == EntityTypeEnum.GENE){
        Khonsole.warn('TBD: Support signalCommonSidepanel for GENE entity.');
      } else {
        OncoData.instance.currentCommonSidePanel.setSelectionPatientIds({
            patientIds: patientIdsForCommonSurvival, existingCohort: selectionSource == "Cohort" ? "Cohort" :
              (selectionSource == "Legend" ? "Legend" : null), selectionModifiers: null, graphConfig
          });
        OncoData.instance.currentCommonSidePanel.drawWidgets();
      }
    }
  }

  destroy() {
    super.destroy();
    this.selectionController.destroy();
    if (this.selectSubscription) {
      if (!this.selectSubscription.closed) {
        this.selectSubscription.unsubscribe();
      }
    }
    this.removeObjects();
  }

  // Look up the decorator's legend
  static scatterFindAnyCustomPaletteForDecorator(database:string, decorator: DataDecorator, markerResults:any){
    let isColorBy = decorator.field.key == 'ColorBy' && decorator.field.label.startsWith('ColorBy:')
    if (isColorBy == null) {
      Khonsole.warn(`WARN: No legend yet in findAnyCustomPaletteForDecorator.`)
    } else {
      Khonsole.warn(`WARN: ColorBy found findAnyCustomPaletteForDecorator.`)
      let foo = decorator.field.label;  // label has prefix "Color By ".
      // Khonsole.warn(`Number field [${isColorBy.name}], see if we have a palette setting.`)
      let customPaletteTxt = ChartFactory.readCustomValueFromLocalStorage(database, 'legendColors', foo + '#palette');
      if (customPaletteTxt) {
        let re = /\\/g;
        customPaletteTxt = customPaletteTxt.replace(re, '"');
        let customPalette: CustomPalette = JSON.parse(customPaletteTxt);
        decorator.customPalette = customPalette;
        Khonsole.warn(`customPalette found for ${isColorBy}.`);

        // Each time we come in here the .values will be default, from the compute process (true?).
        // Swap the colors in .values with the colors in the custom palette.

        Khonsole.warn('customPalette: loop through .values and swap colors from new palette.');
        // Khonsole.log(`customcolor = ${customColor}.`);
        // let threeColor: THREE.Color = new Color(customColor);
        // let threeColorAsPoundHex = '#' + threeColor.getHexString().toLowerCase();
        // let oldColor = legend.values[label];
        // let newColor = threeColorAsPoundHex; // customColor;
        // legend.values[label] = newColor;
        // // Now need to update all .values entries that had that old color.
        // decorator.values.map(v => {
        //   if (v.value == oldColor) {
        //     v.value = newColor;
        //   }
        //   return v;
        // });


        // let actualMatrixValues = markerResults.d;
        // sampleIdsInOrder.forEach(entry => {
        //   let sid = entry.s;
        //   let value = actualMatrixValues[entry.i]

        if(markerResults){
          Khonsole.warn('customPalette: for ColorBy (rna), need to process markerResults here.');
        } else {
          console.error("No marker results.")
        }

        let min = 1000000000000;
        let max = -1000000000000;
        let d: Array<number> = [];
        decorator.values.forEach(v => {
          let rawValue = v["mj_raw_value"];
          if (rawValue) {
            d.push(rawValue);
            if(rawValue < min) { min = rawValue; }
            if(rawValue > max) { max = rawValue; }
          }
        });
        let tblResults = { d: d, min: min, max: max };

        let scale = DataService.instance.getColorByColorScale(decorator.field, tblResults, decorator.customPalette);
        decorator.values.forEach(v => {
          let rawValue = v["mj_raw_value"];
          if(rawValue){
            // set value and mj_raw_value to new color.
            let scaledValue = scale(rawValue).toString().toLowerCase();
            v.value = scaledValue;
            v.label = scaledValue;
          }
        });

        // let counter = 0;
        // let actualMatrixValues = markerResults.d;
        // sampleIdsInOrder.forEach(entry => {
        //   let sid = entry.s;
        //   let value = actualMatrixValues[entry.i]
        //   if (Number.isInteger(value) == false) {
        //     useReals = true;
        //   }
        //   let scaledValue = scale(value)

        //   let pid = sampleMap[sid];
        //   let result = {
        //     pid: pid,
        //     sid: sid,
        //     mid: markerKey,
        //     key: EntityTypeEnum.GENE, // ???
        //     label: value,
        //     value: scaledValue
        //   }
        //   decorator.values[counter] = result;
        //   counter++;
        // })

      }
    }
  }

  updateDecoratorBasedOnStoredColors(decorator: DataDecorator) {
    // Here is where we want to substitute custom colors in.
    let legend = decorator.legend;
    if(legend){
      let self = this;
      //if (legend.type =='COLOR' && legend.labels) {

      let spacey_legend_name = String(legend.name).replace(OncoData.spacey_legend_name_regex, ' ');

      let field = (OncoData.instance.dataLoadedAction.fields.filter(x =>
        String(x.key).replace(OncoData.spacey_legend_name_regex, ' ').toLowerCase() == spacey_legend_name.toLowerCase())[0]);
      if(field == null){
        Khonsole.error(`Field ${legend.name} not found in dataLoadedAction.fields.  updateDecoratorBasedOnStoredColors`);
        return;
      }
      if (field.type == 'NUMBER'){
        AbstractScatterVisualization.scatterFindAnyCustomPaletteForDecorator(self._config.database, decorator, null);

        Khonsole.warn("updateDecoratorBasedOnStoredColors -- Should we see if all values are integer, and if so look for custom colors?");

        // Legend labels already created. Here we want to check legend values (colors) for custom colors.
        for (let label in legend.labels) {
          let cleanLabel: string = ChartFactory.cleanForLocalStorage(legend.labels[label]);
          let customColor = ChartFactory.readCustomValueFromLocalStorage(self._config.database, 'legendColors', legend.name + '!' + cleanLabel);
          if (customColor) {
            Khonsole.log("customColor found for number val/range in updateDecoratorBasedOnStoredColors.")
            Khonsole.warn("TBD");
          }
        }

        // update legend values based on custompalette lookup.
        if(decorator.customPalette && legend.values && legend.values.length > 0){
          let customPaletteValues = ChartFactory.colorsContinuous("CONTINUOUS", decorator.customPalette);
          if (customPaletteValues && customPaletteValues.length == legend.values.length-1) {
            let clonedArray = [...customPaletteValues];
            let naColorAsHex = '#' + (0xffffff + DataService.naDefaultColor + 1).toString(16).substr(1);
            clonedArray.push(naColorAsHex);
            legend.values = clonedArray;
          }
        }

      } else {
        for (let label in legend.labels) {
          let cleanLabel:string = ChartFactory.cleanForLocalStorage(legend.labels[label]);
          let customColor = ChartFactory.readCustomValueFromLocalStorage(self._config.database, 'legendColors', legend.name + '!' + cleanLabel);
          if(customColor) {
            Khonsole.log(`Updating legend.label[${label}] with customcolor = ${customColor}.`);
            let threeColor:THREE.Color = new Color(customColor);
            let threeColorAsPoundHex = '#' + threeColor.getHexString().toLowerCase();
            let oldColor = legend.values[label];
            let newColor = threeColorAsPoundHex; // customColor;
            legend.values[label] = newColor;
            // Now need to update all .values entries that had that old color.
            decorator.values.map(v => {
              if(v.value == oldColor) {
                v.value = newColor;
              }
              return v;
            });
            // Replace the old property key with the new
            if (oldColor != newColor) {
              let foundRow = decorator.pidsByLabel.find(v => v.label == oldColor);
              if (foundRow) {
                foundRow.label = newColor;
              }
              Khonsole.log(`TEMPNOTE: swapped ${oldColor} for ${newColor}.`);
            }
          }
        }

      }
    }
    }

  updateDecorator(config: GraphConfig, decorators: DataDecorator[]) {
    super.updateDecorator(config, decorators);
    let self = this;

    // let visibilityLevels:Float32Array = new Float32Array(this.ids.length);
    // this.ids.forEach((id, index) => {
    //   visibilityLevels[index] = 1.0;
    // });
    // this.pointsGeometry.setAttribute('gVisibility', new THREE.BufferAttribute(visibilityLevels, 1));
    this.setVisibilityBasedOnLegends(config, decorators);

    // No SELECT decorators, so unhighlight all points.
    if (this.decorators.filter(d => d.type === DataDecoratorTypeEnum.SELECT).length === 0) {
      this.selectionController.reset();
      const gSel = this.pointsGeometry.attributes.gSelected;
      let l = gSel.array.length;
      for(let i=0; i < l; i++){
        gSel.setX(i, 0);
      }
      this.pointsGeometry.attributes.gSelected.needsUpdate = true;
      Khonsole.warn('== in updateDecorator with ZERO points==');
      this.notifyEdgeGraphOfSelectionChange(true);
      ChartScene.instance.render();
    }

    // No COLOR decorators, so restore original colors.
    if (this.decorators.filter(d => d.type === DataDecoratorTypeEnum.COLOR).length === 0) {
      let col = new THREE.Color(0);
      col.r = this.defaultPointColorR;
      col.g = this.defaultPointColorG;
      col.b = this.defaultPointColorB;
      this.ids.forEach((id, index) => {
        self.colors[index * 3] = col.r;
        self.colors[index * 3 + 1] = col.g;
        self.colors[index * 3 + 2] = col.b;
      });
      this.pointsGeometry.setAttribute('gColor', new THREE.BufferAttribute(this.colors, 3));
    }

    // No SHAPE decorators, so restore original circle shape (index 0).
    if (this.decorators.filter(d => d.type === DataDecoratorTypeEnum.SHAPE).length === 0) {
      this.ids.forEach((id, index) => {
        self.shapes[index] = 0;
      });
      this.pointsGeometry.setAttribute('gShape', new THREE.BufferAttribute(this.shapes, 1));
    }

    // No SIZE decorators, so restore original circle size (-1 is a clue for the vert function))

    // FIXME: We may also need to check if BASE_SIZE is not defined
    if (this.decorators.filter(d => d.type === DataDecoratorTypeEnum.SIZE).length === 0) {
      let markerScales:Float32Array = new Float32Array(this.ids.length);
      this.ids.forEach((id, index) => {
          markerScales[index] = -1.0;
      });
      this.pointsGeometry.setAttribute('gMarkerScale', new THREE.BufferAttribute(markerScales, 1));
    }


    const propertyId = this._config.entity === EntityTypeEnum.GENE ? 'mid' : 'sid';
    decorators.forEach(decorator => {
      // // 1. Among other things, for each decorator, hide if visibility in legend is 0.

      // if(decorator.legend) {

      //   decorator.legend.visibility.map((legendItemVisibility, legendItemIndex) => {
      //     if(legendItemVisibility < 0.5){
      //       // visibilityLevels:Float32Array = new Float32Array(this.ids.lengt
      //       let pidsToHide = decorator.pidsByLabel[legendItemIndex].pids;
      //       pidsToHide.map((pid, pidIndex) => {
      //         let sid = OncoData.instance.currentCommonSidePanel.commonSidePanelModel.patientMap[pid];
      //         if(sid) {
      //           let scatterIdIndex = this.ids.findIndex(v => v === sid);
      //           visibilityLevels[scatterIdIndex] = 0;
      //         }
      //         //.sampleMap[sampleId];

      //       })
      //     }
      //   });

      //   this.pointsGeometry.setAttribute('gVisibility', new THREE.BufferAttribute(visibilityLevels, 1));
      //   self.pointsGeometry.attributes.gVisibility.needsUpdate = true;

      // }


      //  Decorator specific
      switch (decorator.type) {
        case DataDecoratorTypeEnum.SELECT:
          this.notifyEdgeGraphOfSelectionChange(decorator.values.length == 0);

          if (this._config.entity === EntityTypeEnum.SAMPLE) {
            const indices = decorator.values.map(datum => {
              return this.ids.findIndex(v => v === datum.sid);
            });
            //const arr = this.pointsGeometry.attributes.gSelected.array;
            const gSel = this.pointsGeometry.attributes.gSelected;

            // zero it out
            let l = gSel.array.length;
            for(let i=0; i < l; i++){
              gSel.setX(i, 0);
            }
            // Khonsole.log('== updateDecorator, set selected only if visible');
            indices.forEach(v => {
              gSel.setX(v, 1);
            });

            self.pointsGeometry.attributes.gSelected.needsUpdate = true;
            ChartScene.instance.render();
          }
          break;

        case DataDecoratorTypeEnum.SHAPE:
          const textureLookup = AbstractScatterVisualization.textureImages.reduce((p, c, i) => {
            p['s' + c.replace('.png', '-legend.png')] = i;
            return p;
          }, {});
          const shapeMap = decorator.values.reduce((p, c) => {
            p[c[propertyId]] = textureLookup['s' + c.value];
            return p;
          }, {});
          self.ids.forEach((id, index) => {
            self.shapes[index] = shapeMap[id];
            if (self.shapes[index] === undefined) {
              self.shapes[index] = 7;
            }
          });
          self.pointsGeometry.setAttribute('gShape', new THREE.BufferAttribute(this.shapes, 1));
          ChartScene.instance.render();
          break;

        case DataDecoratorTypeEnum.COLOR:
          self.updateDecoratorBasedOnStoredColors(decorator);
          const colorsMap = decorator.values.reduce((p, c) => {
            const color = new THREE.Color();
            color.set(c.value);
            p[c[propertyId]] = color;
            return p;
          }, {});
          self.ids.forEach((id, index) => {
            let col = null;
            if(colorsMap.hasOwnProperty(id)) {
              col = colorsMap[id]
            } else {
              col = new THREE.Color(0x000000);
            }
            self.colors[index * 3] = col.r;
            self.colors[index * 3 + 1] = col.g;
            self.colors[index * 3 + 2] = col.b;
          });
          self.pointsGeometry.setAttribute('gColor', new THREE.BufferAttribute(this.colors, 3));
          ChartScene.instance.render();
          break;

        case DataDecoratorTypeEnum.SIZE:
          let markerScales:Float32Array = new Float32Array(this.ids.length);
          const sizesMap = decorator.values.reduce((p, c) => {
            const aSize:number =  c.value;
            p[c[propertyId]] = aSize;
            return p;
          }, {});
          let outerIndex:number = -1;
          self.ids.forEach((id, index) => {
            try {
              outerIndex = index;
              let size = 0;
              if(sizesMap.hasOwnProperty(id)) {
                size = sizesMap[id]
              }
              markerScales[index] = size;
                /* let xval:any = decorator.values[index];
              markerScales[index] = xval.value ; */
            } catch (err) {
              Khonsole.error('Error setting size in geom. Index=' + outerIndex );
            }
          });
          self.pointsGeometry.setAttribute('gMarkerScale', new THREE.BufferAttribute(markerScales, 1));
          ChartScene.instance.render();
          break;
        case DataDecoratorTypeEnum.BASE_SIZE:
          this.updateMarkerBaseSize(decorator.values[0].value);
          break;
        case DataDecoratorTypeEnum.OPACITY:
          this.updateMarkerOpacity(decorator.values[0].value);
          break;
        }
    });
    const dataAdjustmentsFor2D = self.adjustFor2DIfNecessary(this.data);
    this.render(dataAdjustmentsFor2D)
    this.selectionController.points = this.points;
    this.selectionController.tooltips = this.ids.map(v => {
      return [{ key: 'id', value: v }];
    });
  }

  public adjustGraphDetailsBasedOnZoomChange(oldZoom:number, newZoom:number, addHistory: boolean) {
    let sc = this.selectionController ;
    if(addHistory){
      sc.addZoomHistory(oldZoom, newZoom);
    }
  }

  /**
   * @description Renders the graph, making any prerender adjustments
   * @param dataAdjustmentsFor2D - Any adjustments to be made to the data before the render to 2D.
   */
  private render(dataAdjustmentsFor2D?: DataAdjustmentsFor2D) {
    if (dataAdjustmentsFor2D && dataAdjustmentsFor2D.needsAdjustment && !this.justAdjustedFor2D) {

      // set the justAdjustedFor2D flag to true so we don't get stuck in a loop
      this.justAdjustedFor2D = true;

      // keep a copy of the data before we modify it
      const dataBeforeAdjustments: GraphData = {...this.data}
      let modifiedData: GraphData = {...this.data};

      // apply the ZValuesFor2D to the data
      if (dataAdjustmentsFor2D.ZValuesFor2D) {

        modifiedData = {
          ...this.data,
          result: this._data.result.map((row, index) => {
            return [row[0], row[1], dataAdjustmentsFor2D.ZValuesFor2D![index]];
          }),
          resultScaled: this._data.resultScaled.map((row, index) => {
            return [row[0], row[1], dataAdjustmentsFor2D.ZValuesFor2D![index]];
          }),
          adjustedZFor2D: true
        };
      }

      // Apply the modified data to the graph
      super.updateData(this._config, modifiedData);
      this.removeObjects();
      this.addObjects();
      ChartScene.instance.render();

      // revert the data back to its original state
      super.updateData(this._config, dataBeforeAdjustments);
      return;
    }

    // reset the justAdjustedFor2D flag
    this.justAdjustedFor2D = false;

    super.updateData(this._config, {...this.data, justAdjustedFor2D: false});


    ChartScene.instance.render();

  }

  // First time we get a pseudo-2D viz, store camera position and rotation.
  // Restore it if we come back from 3D, because 3D can skew/distort the pseudo-2D view.
  private first_time_2d_camera_values: {
    cameraPosition: THREE.Vector3 | null,    // THREE.Vector3 to store camera's position
    cameraQuaternion: THREE.Quaternion | null,  // THREE.Quaternion to store camera's rotation, more accurate than Euler angles
    controlsTarget: THREE.Vector3 | null,    // THREE.Vector3 to store OrbitControls' target
  } = {
    cameraPosition: null,
    cameraQuaternion: null,
    controlsTarget: null,
  }

  private shouldResetCameraWhenReturnTo2D = false;
  private justAdjustedFor2D: boolean = false;

  /**
  * Adjusts camera and controls based on the dimensionality of the data.
  * @param config - Graph configuration settings.
  * @param data - Graph data containing results and additional information.
  * @returns - Any adjustments to be made later to thr data for 2D.
  */
  private adjustFor2DIfNecessary(data: GraphData): DataAdjustmentsFor2D {

    let dataAdjustments: DataAdjustmentsFor2D = {
      needsAdjustment: false,
      ZValuesFor2D: null
    }

    const naZValue = -5;

    // Reference to the current instance
    let self = this;

    // Extract camera from the view
    let cam = self.view.camera;

    // Determine if the data is effectively in 2D based on all zero z-values
    let dataIsEffectively2D = data.result.every((row: number[]) => row[2] === 0);
    dataAdjustments.needsAdjustment = dataIsEffectively2D;

     // Reset camera to initial 2D values if necessary
    if (dataIsEffectively2D && self.shouldResetCameraWhenReturnTo2D) {
      cam.position.copy(self.first_time_2d_camera_values.cameraPosition!);
        cam.quaternion.copy(self.first_time_2d_camera_values.cameraQuaternion!);
        self.view.controls.target.copy(self.first_time_2d_camera_values.controlsTarget!);
        self.shouldResetCameraWhenReturnTo2D = false;
        self.view.controls.update();
    }

    // Record initial 2D camera values if not already recorded
    if (dataIsEffectively2D && self.first_time_2d_camera_values.cameraPosition == null) {
      self.first_time_2d_camera_values = {
        cameraPosition: cam.position.clone(),
        cameraQuaternion: cam.quaternion.clone(),
        controlsTarget: self.view.controls.target.clone()
      };
    }

    // If we have been in 2D before but are now in 3D, record that we want to restore 2D values.
    if (!dataIsEffectively2D && self.first_time_2d_camera_values.cameraPosition != null) {
      self.shouldResetCameraWhenReturnTo2D = true;
      console.warn("===YES=== we left a 2D viz.");
    } else {
      console.warn("===NO=== we have not left a 2D viz, at least this time.");
    }

    if (dataIsEffectively2D) {

      // Check for a color decorator
      const colorDecorator = this.decorators.find(d => d.type === DataDecoratorTypeEnum.COLOR);

      if (colorDecorator) {
        // Push points with 'na' color label to the back in 2D
        const naLegendLabelIndex = colorDecorator.legend.labels.findIndex(l => l.toLowerCase() === 'na');

        if (naLegendLabelIndex > -1) {
          const pidsWithNa = new Set(colorDecorator.pidsByLabel![naLegendLabelIndex].pids);
          const pids = data.pid;

          /** If the corresponding pid of the row has an NA value in the legend, return the Z Value for 2D */
          const getZValueFor2D = (row, index) => {
            return pidsWithNa.has(pids[index]) ? naZValue : row[2];
          };

          // Apply the getZValueFor2D function to update z-values in data
          dataAdjustments.ZValuesFor2D = data.result.map(getZValueFor2D);
        }

      // Disable rotation in 2D
      self.selectionController.setEverCanRotateFlag(false);
      console.warn("2D, no rotation.");
      }
    } else {
      if (this.justAdjustedFor2D) {
        self.selectionController.setEverCanRotateFlag(false);
        console.warn("2D, no rotation (from adjustment).");
      } else {
        // Enable rotation in 3D
        self.selectionController.setEverCanRotateFlag(true);
        console.warn("3D, free to rotate.");

        // allow adjustments to be made to the data when we go back to 2D
        this.justAdjustedFor2D = false;
      }

    }

    self.setRotationOnviewControls();

    return dataAdjustments;
  }



  updateData(config: GraphConfig, data: GraphData) {
    let self = this;
    super.updateData(config, data);
    self.removeObjects();
    self.addObjects();
    const dataAdjustmentsFor2D = self.adjustFor2DIfNecessary(data);
    this.render(dataAdjustmentsFor2D)
  }

  setRotationOnviewControls(){
    let canRotate = this.selectionController.canEverRotateFlag();  // true;
    Khonsole.warn(`canRotate abstractScatter? ${canRotate}.`);
    this.view.controls.enableRotate = canRotate;
  }

  enable(truthy: boolean) {
    super.enable(truthy);
    this.setRotationOnviewControls();
  }

  addObjects() {
    this.lines = [];
    const propertyId = this._config.entity === EntityTypeEnum.GENE ? 'mid' : 'sid';
    this.ids = this._data[propertyId];
    this.positionsFrame = 0;
    // these were "this._data.resultScaled.length - 1"
    let arrayPositionsCount:number =this._data.resultScaled.length; // was ... - 1
    this.positionsPrev = new Float32Array(arrayPositionsCount * 3);
    this.positions = new Float32Array(arrayPositionsCount * 3);
    this.colors = new Float32Array(arrayPositionsCount * 3);
    this.shapes = new Float32Array(arrayPositionsCount);
    this.selected = new Float32Array(arrayPositionsCount);
    this.sizes = new Float32Array(arrayPositionsCount);
    Khonsole.log(`AddObjects ${this._data.resultScaled.length} now in visualization.abstract.scatter.components.ts at ${Date.now()}.`);

    this._data.resultScaled.forEach((point, index) => {
      this.selected[index] = 0.0;
      this.shapes[index] = 0.0;
      this.sizes[index] = -1.0; // Anything < 0 means use default size. TBD TEMPNOTE: Pass in value from mat-slider.
      this.colors[index * 3] = this.defaultPointColorR;
      this.colors[index * 3 + 1] = this.defaultPointColorG;
      this.colors[index * 3 + 2] = this.defaultPointColorB;
      for (let i = 0; i < 3; i++) {
        this.positionsPrev[index * 3 + i] = point[i];
        this.positions[index * 3 + i] = point[i];
      }
    });

    this.pointsGeometry = new THREE.BufferGeometry();
    this.pointsGeometry.setAttribute('gPositionFrom', new THREE.BufferAttribute(this.positionsPrev, 3));
    this.pointsGeometry.setAttribute('position', new THREE.BufferAttribute(this.positions, 3));
    this.pointsGeometry.setAttribute('gColor', new THREE.BufferAttribute(this.colors, 3));
    this.pointsGeometry.setAttribute('gShape', new THREE.BufferAttribute(this.shapes, 1));
    this.pointsGeometry.setAttribute('gMarkerScale', new THREE.BufferAttribute(this.sizes, 1));
    // this.pointsGeometry.setAttribute('gSize', new THREE.BufferAttribute(this.sizes, 1));
    // this.pointsGeometry.setAttribute('gAlpha', new THREE.BufferAttribute(this.alphas, 1));
    this.pointsGeometry.setAttribute('gSelected', new THREE.BufferAttribute(this.selected, 1));

    let uniforms = Object.assign(
      {
        uAnimationPos: { value: this.positionsFrame },
        uMarkerBaseSize: { value: this._config.getLegendOptionValue(LegendOptionEnum.MARKER_BASE_SIZE) as number },
        uMarkerOpacity: { value: this._config.getLegendOptionValue(LegendOptionEnum.MARKER_OPACITY) as number },
      }
      // AbstractScatterVisualization.textures
    );

    this.pointsMaterial = new THREE.ShaderMaterial({
      uniforms: uniforms,
      transparent: true,
      vertexShader: vertShader, //vertShaderNoAttenuation, // vertShader,
      fragmentShader: fragShader,
      alphaTest: 0.5
    });

    this.points = new THREE.Points(this.pointsGeometry, this.pointsMaterial);
    this.points.userData['ids'] = this.ids;
    this.meshes.push(this.points);
    this.view.scene.add(this.points);
    this.selectionController.points = this.points;
    this.selectionController.tooltips = this.ids.map(v => {
      return [{ key: 'id', value: v }];
    });

    this.tooltipController.targets = this.meshes;


    // /* MJ Could add AxesHelper and some GridHelpers to give orientation context . -MJ */
    // var axesHelper = new THREE.AxesHelper(400);
    // this.view.scene.add( axesHelper );

    // var gridV = new THREE.GridHelper(150, 5, 0x0000ff, 0x808080);
    // gridV.position.y = 75;
    // gridV.position.x = 75;
    // gridV.rotation.x = -Math.PI / 2;
    // this.view.scene.add(gridV);

    // /*
    // gridV = new THREE.GridHelper(150, 5, 0x0000ff, 0x808080);
    // gridV.position.y = 75;
    // gridV.position.x = 75;
    // gridV.rotation.y = -Math.PI / 2;
    // this.view.scene.add(gridV);
    // gridV = new THREE.GridHelper(150, 5, 0x0000ff, 0x808080);
    // gridV.position.y = 75;
    // gridV.position.x = 75;
    // gridV.rotation.z = -Math.PI / 2;
    // this.view.scene.add(gridV);
    // */

    this.updateDecorator(this.config, this.decorators);
    Khonsole.log(`before a configPerspectiveOrbit ${this.view.camera.position.length()}`);
    // this.tooltipController.targets = this.points;
    ChartFactory.configPerspectiveOrbit(
      this.view,
      new THREE.Box3(new Vector3(-250, -250, -250), new THREE.Vector3(250, 250, 250))
    );
    Khonsole.log(`after configPerspectiveOrbit ${this.view.camera.position.length()}`);
    this.lastZoomDistance = this.view.camera.position.length();
    this.originalZoomDistance = this.lastZoomDistance;
  }

  removeObjects() {
    this.view.scene.remove(...this.meshes);
    this.meshes.length = 0;
  }

  onShowLabels(): void {
    // const labelOptions = new LabelOptions(this.view, 'FORCE');
    // labelOptions.offsetX3d = 1;
    // labelOptions.maxLabels = 100;
    // this.labels.innerHTML = LabelController.generateHtml(
    //   this.meshes,
    //   labelOptions
    // );
  }
}
