import { Khonsole } from 'app/khonsole';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
  EventEmitter
} from '@angular/core';

import * as _ from 'lodash';
import { GraphConfig } from '../../../model/graph-config.model';
import { DataDecorator, LegendFilter } from './../../../model/data-map.model';
import { Legend } from './../../../model/legend.model';
import { ChartFactory } from '../chart/chart.factory';
import * as THREE from 'three';
import { SelectionModifiers } from 'app/component/visualization/visualization.abstract.scatter.component';
import { OncoData } from 'app/oncoData';
import { ChartScene } from '../chart/chart.scene';
import { VisualizationView } from '../../../model/chart-view.model';
import { AbstractScatterVisualization } from '../../visualization/visualization.abstract.scatter.component';
import { LegendEyeComponent } from './legend-eye/legend-eye.component';
import { VisualizationEnum } from 'app/model/enum.model';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-workspace-legend-panel',
  templateUrl: './legend-panel.component.html',
  styleUrls: ['./legend-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class LegendPanelComponent implements AfterViewInit {
  @ViewChildren(LegendEyeComponent) legendItems: QueryList<LegendEyeComponent>
  // Using ViewChildren to query for MatTooltip instances
  @ViewChildren(MatTooltip) legendTooltipsQuery: QueryList<MatTooltip>;

  // Array to hold MatTooltip instances
  legendTooltips: MatTooltip[] = [];

  public static setLegends = new EventEmitter<{
    legend: Array<Legend>;
    graph: number;
  }>();
  public autoUpdate = true;

  public allLegends: Array<Legend> = [];
  public updateLegend = _.debounce(this.update, 600);

  public _config: GraphConfig;
  get config(): GraphConfig {
    return this._config;
  }
  @Input()
  set config(value: GraphConfig) {
    if (value === null) {
      return;
    }

    this._config = value;
    this.updateLegend();
  }

  private _decorators: Array<DataDecorator> = [];
  @Input()
  public set decorators(value: Array<DataDecorator>) {
    if (value === null) {
      return;
    }
    if(this._decorators != value){
      this._decorators = value;
      this.updateLegend();
    }
  }

  public _legends: Array<Legend> = [];
  @Input()
  public set legends(value: Array<Legend>) {
    if (value === null) {
      Khonsole.log(`TEMPNOTE: Input for legend-panel was null.`);
      return;
    }
    this._legends = value;
    this.updateLegend();
  }

  public _legendFilters: Array<LegendFilter> = [];
  @Input()
  public set legendFilters(value: Array<LegendFilter>) {
    if (value === null) {
      Khonsole.log(`TEMPNOTE: Input legendFilters for legend-panel was null.`);
      return;
    }
    this._legendFilters = value;
    this.updateLegendFilters();
  }

  updateLegendFilters(){
    Khonsole.warn('## updateLegendFilters NYI ##');
  }

  ngAfterViewInit(): void {
    // Convert QueryList to an array for easier indexing
    this.legendTooltips = this.legendTooltipsQuery.toArray();
    }

  customizeColor(legend: Legend, i:number): void {
    Khonsole.log(`MJ click on legend item [${i}] color box`);
    let color = prompt('Type a color name like red or green, or a hex code like #440080.', '#440080')
    if(color) {
      ChartFactory.writeCustomValueToLocalStorage(this._config.database, 'legendColors', legend.name + '!' + ChartFactory.cleanForLocalStorage(legend.labels[i]), color);
      window.setTimeout(this.update, 100);
    }
  }

  private setLegendItemVisibility(li:LegendEyeComponent, i:number, beVisible: boolean){
      li.visible = beVisible;
      li.legend.visibility[i] = beVisible ? 1 : 0;
  }

  isntDataPointsPlaceholder(legend){
    let result = true;
    try {
      if(legend.type === 'SHAPE'){
        result = legend.name != "Data Points" && legend.values.length > 1;
      }
    } catch (e){
      Khonsole.error(`Error in isntDataPointsPlaceholder: ${e}`);
    }
    return result;
  }

  allLegendsExceptDataPointsPlaceholder(){
    let self = this;
    let result = this.allLegends.filter(l => self.isntDataPointsPlaceholder(l) );
    return result;
  }

  onEyeClick(activity: {
    i: number;
    legend: Legend;
    event: Event;
  }) {
    if(!this.config.isScatterVisualization) {
      Khonsole.warn('In onEyeClick, clicking non-scatter vis. Not yet supported..');
      return;
    }

    // Get current vis of clicked item.
    let currentEyeVis = true;
    this.legendItems.forEach( (li, i) => {
      if(i == activity.i){
        currentEyeVis = li.visible;
      }
    });

    this.legendItems.forEach( (li, i) => {
      if(i == activity.i){
        this.setLegendItemVisibility(li, i, currentEyeVis == false)
      } else {
        if((activity.event as MouseEvent).altKey){
          this.setLegendItemVisibility(li, i, currentEyeVis) // e.g., if item was true, all others now become true.
        }
      }
    });

    Khonsole.warn('== Assuming view 0 in onEyeClick ==');
    let view:VisualizationView = ChartScene.instance.views[0];
    let thisScatterGraph  = view.chart as AbstractScatterVisualization;
    if(thisScatterGraph && thisScatterGraph.isBasedOnAbstractScatter){
      thisScatterGraph.removeInvisiblesFromSelection(view.config, view.chart.decorators);
    } else {
      Khonsole.warn('This vis does not support removeInvisiblesFromSelection.');
    }
    ChartScene.instance.render();
    OncoData.instance.currentCommonSidePanel.drawWidgets();
  }

  countAttempt(lf:LegendFilter){
    Khonsole.warn('in countAttempt');
    Khonsole.dir(lf)
    return 0;
  }

  formattedLegendItemText(legend:Legend, i:number){
//    {{label}}{{legend.counts && legend.counts[i]?'&nbsp;&nbsp;&nbsp;('+ selectionOf(legend,i)+ legend.counts[i]+')':''
    const itemCountsExist = legend.counts && legend.counts[i];
    let txt = `${legend.labels[i]}${itemCountsExist?' ('+ this.selectionOf(legend,i)+ legend.counts[i]+')':''}`;
    return txt;
  }

  legendItemHasSelectedPoints(legend:Legend, i:number){
    if (legend.selectionCounts && legend.selectionCounts[i] && legend.selectionCounts[i]>0) {
      return true;
    } else {
      return false;
    }
  }

  selectionOf(legend:Legend, i:number){  // Returns "3 of " part of "3 of 5".
    if (legend.selectionCounts) {
      let count = legend.selectionCounts[i];
      if (count == 0) { // none selected
        return ""
      } else {
        return `${count} of `
      }
    } else {
      return ""
    }
  }

  smarterName(name:string){
    if(name){
      if (name.startsWith("Color By ColorBy:")){
        name = name.replace("ColorBy:", "");
        let equalStart = name.indexOf("=")
        name = "Color By " + name.substring("color By ".length, equalStart).toUpperCase()
          + name.substring(equalStart);
        return name;
      } else {
        return name;
      }
    } else {
      // name is null
      Khonsole.warn("Unnamed Legend in smarterName()");
      return "Unnamed Legend"
    }
  }

  getClickedPidsFromLegendItem(legend: Legend, i: number): string[] | null {
    let dec:DataDecorator = ChartScene.instance.views[0].chart.decorators.find(d => d.field && (d.field.label == legend.name));
    if (dec == null) {
      dec = ChartScene.instance.views[0].chart.decorators.find(d => d.field && ( "Color By " + d.field.label == legend.name));
    }
    if (dec == null) {
      Khonsole.warn('Null decorator in clickedPidsFromLegendItem.');
      return;
    }

    if (dec.pidsByLabel != null) {
      let clickedColorValue: string = legend.values[i];
      let clickedPids = dec.pidsByLabel.find(v => v.label == clickedColorValue);
      if (!clickedPids) {
        let a: THREE.Color = new THREE.Color(clickedColorValue);
        let colorInt = a.getHex();
        clickedPids = dec.pidsByLabel.find(v => v.label == colorInt.toString());
      }
      if (clickedPids) {
        if (clickedPids.pids) {
          Khonsole.log(`clickedPids.length = ${clickedPids.pids.length}.`)
        } else {
          Khonsole.warn('clickedPids is undefined in clickedPidsFromLegendItem.')
        }
        return clickedPids.pids;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getClickedPidsPerEvent(legend: Legend, i: number): string[] | null {
    if (ChartScene.instance.views[0].config.visualization != VisualizationEnum.TIMELINES || !window['clickedPidsPerEvent']) {
      return null;
    }

    let basekey = legend.name; // legend.name.startsWith("ROW // ") ? legend.name.substring("ROW // ".length) : legend.name;
      Khonsole.log(`basekey=${basekey}.`)
      let subtype = legend.labels[i];
      let typeSubtype = (basekey+":"+subtype).toLowerCase()
      let typeSubtypeArray = window['clickedPidsPerEvent'][typeSubtype]
      if (typeSubtypeArray == null) {
        typeSubtypeArray = window['clickedPidsPerEvent'][typeSubtype+":"]
      }
      if (typeSubtypeArray == null) {
        typeSubtypeArray = window['clickedPidsPerEvent']["row // " + typeSubtype]
      }
      if (typeSubtypeArray == null) {
        typeSubtypeArray = window['clickedPidsPerEvent']["row // " + typeSubtype + ":"]
      }
      if (typeSubtypeArray == null) {
        // Try removing "row // " from front.
        if (typeSubtype.startsWith("row // ")){
          let typeSubtypeWithoutRow = typeSubtype.substring(7);
          typeSubtypeArray = window['clickedPidsPerEvent'][typeSubtypeWithoutRow]
          if (typeSubtypeArray == null) {
            typeSubtypeArray = window['clickedPidsPerEvent'][typeSubtypeWithoutRow + ":"]
          }
        }
      }
      if (typeSubtypeArray) {
        return Array.from(typeSubtypeArray)
      }

      return null;
  }

  onLegendItemTextClick(legend: Legend, i:number): void {
    Khonsole.log("GG=", "onLegendItemTextClick", legend, i);

    const color: string | null = legend.values[i];

    // build list of matching patient IDs, then
    let patientIds = this.getClickedPidsFromLegendItem(legend, i);

    // if we didn't find a match, then try to get it from the event (happens for timelines)
    if(patientIds == null) {
      patientIds = this.getClickedPidsPerEvent(legend, i);
    }

    if(patientIds != null){
      let mouseEvent: any = event;
      let selectionModifiers:SelectionModifiers = new SelectionModifiers();
      selectionModifiers.extend = mouseEvent.shiftKey;
      selectionModifiers.inverse = mouseEvent.altKey;

      // pass it off to commonSidePanel.setSelectionPatientIds.
      // patientIds length should match legend.counts[i].
      window.setTimeout(() => {
        OncoData.instance.currentCommonSidePanel.setSelectionPatientIds({
          patientIds,
          existingCohort: "Legend",
          preferredCohortColor: color,
          selectionModifiers,
          graphConfig: null,
        });
      }, 20);
      OncoData.instance.currentCommonSidePanel.drawWidgets();

    } else {
      Khonsole.log('Click on label did not resolve by color.');
    }

    // // from commonsidepanel...
    // if(index <= this.definedCohorts.length) {
    //   // this.svg._groups[0][0].getElementsByClassName('km-curve')[0]
    //   let mouseEvent:any = event;
    //   let c = this.definedCohorts[index];
    //   let selectionModifiers:SelectionModifiers = new SelectionModifiers();
    //   selectionModifiers.extend = mouseEvent.shiftKey;
    //   selectionModifiers.inverse = mouseEvent.altKey;

    //   window.setTimeout(() => {
    //     this.setSelectionPatientIds(c.pids, c, selectionModifiers);
    //   }, 20);
    // } else {
    //   alert(`Could not find defined cohort with index of ${index}.`);
    // }

    window.setTimeout(this.update, 100);
  }


  legendFormatter(legend: Legend): Legend {
    if(legend == null){
      Khonsole.error("Expected legend in legendFormatter().")
      return null;
    } else {
      if (legend.values == null) {
        Khonsole.error("Expected legend.values in legendFormatter().")
        return legend
      }

      const rv:Legend = Object.assign({}, legend);
      if(rv.values == null){
        Khonsole.error("NOT valid legend in legendFormatter. 363732");
        alert("Not valid legend in legendFormatter")
        return legend;
      }
      if (rv.type === 'COLOR') {
        for (let i = 0; i < rv.values.length; i++) {
          if (!isNaN(rv.values[i])) {
            legend.values[i] =
              '#' + (0xffffff + legend.values[i] + 1).toString(16).substr(1);
          }
        }
      } else if (legend.type === 'SHAPE') {
        for (let i = 0; i < rv.values.length; i++) {
          if (!isNaN(rv.values[i])) {
            legend.values[i] =
              'https://oncoscape.v3.sttrcancer.org/assets/shapes/shape-' +
              legend.values[i] +
              '-solid-legend.png';
          }
        }
      }
      return rv;
    }
  }

  public isColorLegend(legend: Legend): boolean {
    return legend.type === 'COLOR';
  }

  public update(): void {
    let self = this;
    if (!self.autoUpdate) {
      return;
    }
    const legendsFromDecorators = self._decorators.map(decorator =>
      self.legendFormatter(decorator.legend)
    );

    try {
      const coreLegends = self._legends.map(legend => self.legendFormatter(legend));
    this.allLegends = [].concat(...legendsFromDecorators, ...coreLegends);

    Khonsole.warn(`UPDATE LEGENDS.....`);
    Khonsole.dir(this.allLegends);
    let colorLegend = this.allLegends.find(l => l.type == 'COLOR');
    if(colorLegend){
      let smartName = self.smarterName(colorLegend.name)
      Khonsole.warn(`smartName = ${smartName}.`)
      let header = document.querySelector('.color-legend-header') as HTMLElement;
      if(header){
        let cleanHeader    = header.innerText.trim().toLowerCase().replace(/ /g, "_");
        let cleanSmartName = smartName.trim().toLowerCase().replace(/ /g, "_");
        if (cleanHeader != cleanSmartName) {
          header.classList.remove('fade-text-animation');
          // Trigger a reflow in between removing and adding the class
          const forceReflow =  header.offsetWidth;
          header.scrollTop = header.scrollTop;
          header.classList.add('fade-text-animation');
        }
      } else {
        Khonsole.error("Could not find color-legend-header.")
      }
    }

    // // // // Here is where we want to substitute custom colors in.
    // // // this.allLegends.map ( legend => {
    // // //   if (legend.type =='COLOR' && legend.labels) {
    // // //     for (let label in legend.labels) {
    // // //       let cleanLabel:string = this.cleanForLocalStorage(legend.labels[label]);
    // // //       let customColor = this.readCustomValueFromLocalStorage('legendColors', legend.name + '!' + cleanLabel);
    // // //       if(customColor) {
    // // //         Khonsole.log(`customcolor = ${customColor}.`);
    // // //         legend.values[label] = customColor;
    // // //       }
    // // //     }
    // // //   }
    // // // });

    this.cd.detectChanges();
    } catch (err) {
      Khonsole.error(`TEMPNOTE: error in legend update, probably bad _legends. ${err}`);
    }
  }
  /**
   * @description Display the tooltip for the legend item.
   * @param i The index of the legend item.
   */
  displayTooltip(tooltip: MatTooltip){
    tooltip.disabled = false;
    tooltip.show()
  }

  /**
   * @description Hide the tooltip for the legend item.
   * @param i The index of the legend item.
   */
  hideTooltip(tooltip: MatTooltip) {
    tooltip.disabled = true;
    tooltip.hide()
  }

  trackLegendByFn(index, legend:Legend) {
    return legend.type;  // color, shape, etc., because we only have one of each type max.
  }

  onSetLegends(e: { legends: Array<Legend>; graph: number }): void {
    if (this.config.graph !== e.graph) {
      return;
    }
    this.autoUpdate = false;
    this.allLegends = [].concat(...e.legends); //e.legends;
    this.cd.detectChanges();
  }
  constructor(public cd: ChangeDetectorRef) {
    LegendPanelComponent.setLegends.subscribe(this.onSetLegends.bind(this));
  }


}
