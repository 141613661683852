<div style="height: auto !important">
  <!-- Sometimes we hide legend elements, and way want to let the user know how many are hidden -->
  <div
    *ngFor="let legendFilter of _legendFilters"
    style="border-bottom-width: 1px"
  >
    <div class="legend-header">
      Hidden:
      {{ countAttempt(legendFilter) + legendFilter.excludedValues.length }}
    </div>
  </div>

  <mat-expansion-panel
    *ngFor="
      let legend of allLegendsExceptDataPointsPlaceholder();
      index as i;
      trackBy: trackLegendByFn
    "
    [expanded]="true"
  >
    <!-- Legend Header with an info icon that shows the whole name (useful when truncated for longer ones) -->
    <mat-expansion-panel-header *ngIf="isntDataPointsPlaceholder(legend)">
      <mat-panel-title
        class="legend-header"
        [ngClass]="{ 'color-legend-header': isColorLegend(legend) }"
        style="padding-top: 4px !important; padding-bottom: 4px !important"
      >
        <span>
          {{ smarterName(legend.name) }}
          <i
            matTooltip="{{ smarterName(legend.name) }}"
            matTooltipPosition="right"
            [matTooltipDisabled]="true"
            #legendTooltip="matTooltip"
            matTooltipClass="custom-tooltip"
            class="fa fa-info-circle"
            (mouseenter)="displayTooltip(legendTooltip)"
            (mouseleave)="hideTooltip(legendTooltip)"
          ></i>
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <!-- Shape Items -->
    <div *ngIf="legend.type === 'SHAPE' && isntDataPointsPlaceholder(legend)">
      <div
        *ngFor="let label of legend.labels; let i = index"
        class="legend-item"
      >
        <img class="swatch" src="{{ legend.values[i] }}" />
        <span>&nbsp;&nbsp;&nbsp;&nbsp;{{ label }}</span>
      </div>
    </div>

    <!-- Color Items -->
    <div *ngIf="legend.type === 'COLOR'">
      <div
        *ngFor="let label of legend.labels; let i = index"
        class="legend-item"
      >
        <!-- Eye to toggle visibility -->
        <app-workspace-legend-eye
          *ngIf="config.isScatterVisualization"
          (onClick)="onEyeClick($event)"
          [legend]="legend"
          [i]="i"
        ></app-workspace-legend-eye>

        <!-- Color Box with Color Picker -->
        <div
          class="swatch"
          (click)="customizeColor(legend, i)"
          [style.background-color]="legend.values[i]"
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div
        >

        <!-- Legend Item Text -->
        <div
          class="clickable"
          [ngClass]="{ selected: legendItemHasSelectedPoints(legend, i) }"
          id="ocLegendItem_{{ label }}"
          (click)="onLegendItemTextClick(legend, i)"
          >{{ formattedLegendItemText(legend, i) }}</div
        >
      </div>
    </div>
  </mat-expansion-panel>
</div>
