import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  Renderer2,
} from "@angular/core";

import { HtmlWidgetComponent } from "./html-widget.component";
import * as _ from "lodash";
import { OncoData } from "app/oncoData";
import { ViewChild, ElementRef } from "@angular/core";

@Component({
  selector: "cohorts-widget",
  templateUrl: "./cohorts-widget.component.html",
  styleUrls: ["./common-side-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CohortsWidgetComponent extends HtmlWidgetComponent {
  // @ViewChild('cohortChosen') cohortChosen: ElementRef;
  @ViewChild("selectedCohortTextField", { static: true })
  selectedCohortText: ElementRef;

  @ViewChild("selectedCohortColorCell", { static: true })
  selectedCohortColorCell: ElementRef;

  constructor(renderer: Renderer2) {
    super(renderer);
    this.model.name = "Cohorts";
    this.model.preferredHeight = null; // Autogrow, unlike forced height like "130px";
  }

  parentAddCohortClicked(e): void {
    OncoData.instance.currentCommonSidePanel.addCohortClicked(e);
  }

  parentApplySelectedCohortNameFilter(e): void {
    OncoData.instance.currentCommonSidePanel.applySelectedCohortNameFilter(e);
  }
}
