import { Khonsole } from 'app/khonsole';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatSort, MatTable } from '@angular/material';
import * as JStat from 'jstat';
import { FormBuilder } from '@angular/forms';
import {
  Cohort,
  CohortCondition,
  CohortField
} from '../../../model/cohort.model';
import { StatFactory } from './../../../service/stat.factory';
import { GraphConfig } from '../../../model/graph-config.model';
import { DataService } from '../../../service/data.service';
import { MatProgressBarModule } from '@angular/material'
import { DataField, DataTable } from '../../../model/data-field.model';
import { CollectionTypeEnum } from 'app/model/enum.model';
import { DiffexpWidgetComponent } from  '../common-side-panel/diffexp-widget.component';
import { DiffexpResults } from  '../common-side-panel/diffexpResults';
import { OncoData, LoadedTable } from 'app/oncoData';
import { WorkspaceComponent } from 'app/component/workspace/workspace.component';
import { AppComponent } from 'app/app.component';
import { TmplAstTemplate } from '@angular/compiler';

import * as simp from 'simple-statistics'
import * as d3 from 'd3';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { StatOneD } from 'app/model/stat.model';
import { GoogleChartComponent, GoogleChartsModule } from 'angular-google-charts';
const Stats = require("statsmodels-js");


declare var $: any;

export enum Cardinality {
  Nominal = "nominal",
  Ordinal = "ordinal",
  Discrete = "discrete",
  Continuous = "continuous",
}
export interface CohortComparisonDataItem {
  pos: number;
  category: string;
  metric: string;
  cardinality: Cardinality;
  overview: string; // Descriptive aid. e.g., "Mean: 32.3 vs. 11.2"
  score: any;
  p_value: number;
  dataA: Array<number>;
  dataB: Array<number>;
  dataCategories: Array<string>; // Used for nominal and ordinal data (categories).
  processedCategoryPercentages: any;
}

class SortOptions {
  active: string = 'metric'; // category, metric, difference , significance
  direction: string = 'asc'
}

@Component({
  selector: 'app-cohort-comparison',
  styleUrls: ['./cohort-comparison.component.scss'],
  templateUrl: './cohort-comparison.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None
})
export class CohortComparisonComponent implements AfterViewInit {

  // default cohort colors
  cohortAColor = "blue"
  cohortBColor = "lightBlue"

  @ViewChild('comparisonChart', { static: false }) comparisonChart: GoogleChartComponent;

  private statFactory: StatFactory;
    progressMode = 'determinate';
  progressValue = 0;
  bufferValue = 0;
  // dataOptions:Array<DataTable> = [];

  data: CohortComparisonDataItem[] = [];

  @ViewChild(MatTable, { static: false }) cohortComparisonTable!: MatTable<any>;

  sortData(event: any) {
    Khonsole.warn("sort event....")
    Khonsole.dir(event)
    const column = event.active;
    const direction = event.direction;
    this.data = this.data.sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      } else {
        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return direction === 'asc' ? valueA - valueB : valueB - valueA;
        } else {
          // a number vs a string
          try {
          let valAStr = valueA.toString();
          let valBStr = valueB.toString();
          if (typeof valueA === 'number'){
            return direction === 'asc' ? -1 : 1;
          } else {
            return direction === 'asc' ? 1 : -1;
          }
        } catch (ex){
          Khonsole.warn("A or B is null");
          return 1
        }
        }
      }
    });
    // this.cd.detectChanges();
    if(this.cohortComparisonTable){
      this.cohortComparisonTable.renderRows();
    }
  }

  cleanNumber(n){
    // Khonsole.warn('cleannumber ' + String(n))
    if (n != null){
      return StatFactory.nicelyFormattedNumber(n)
    } else {
      return "---"
    }
  }

  // compareSortColumns(a: number | string, b: number | string, isAsc: boolean) {
  //   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  // }

  @Input()
  cohorts: Array<Cohort> = [];
  tables: Array<DataTable> = [];

  selectedRow: any
  selectedRowIndex: number;

  private _cohortA: Cohort;
  get cohortA(): Cohort {
    return this._cohortA;
  }

  @Input()
  set cohortA(cohort: Cohort) {
    Khonsole.warn(`set cohortA: ${cohort ? cohort.n : "Undefined"}.`)
    if (cohort === null) {
      return;
    }
    this._cohortA = cohort;
    // if the cohort has a color, use it. Otherwise, use the default.
    this.cohortAColor = cohort.assignedColor || this.cohortAColor;
    this.tryRegenerateData();
    this.resetForm();
    this.cd.detectChanges();
  }


  private _cohortB: Cohort;
  get cohortB(): Cohort {
    return this._cohortB;
  }
  @Input()
  set cohortB(cohort: Cohort) {
    if (cohort === null) {
      return;
    }
    this._cohortB = cohort;
    // if the cohort has a color, use it. Otherwise, use the default.
    this.cohortBColor = cohort.assignedColor || this.cohortBColor;
    this.tryRegenerateData();
    this.resetForm();
  }

  hasData() {
    if (this.data) {
      return this.data.length >0
    } else {
      return false
    }
  }

  private svg;

  patientData:Array<any>
  sampleMap:any


  countWordsEqual(arr, testWord) {
    return arr.reduce((count, word) => {
      if (word === testWord) {
        count++;
      }
      return count;
    }, 0);
  }

  tryRegenerateData(){
    // Create data from cohortA and cohortB, if not null.
    // TBD: Avoid calculating this twice.

    let self = this;
    if((this.cohortA && this.cohortB) && (this.cohortA.n != this.cohortB.n)){
      Khonsole.log(this.patientData);
      this.data = []
      let i=0;
      OncoData.instance.dataLoadedAction.fields.map(function(field) {
        Khonsole.log(field.label);
        let uvA = self.getUsableValues(field, self.cohortA)
        let uvB = self.getUsableValues(field, self.cohortB)
        let dataCats = []  // TBD self.getCategories(field)
        let p_value:any = null;
        let cardinality = null;
        let score:any = null;
        let overview:string = "N/A"
        let procCatPercent = {}

        if (field.type == "NUMBER") {
          if (uvA.length < 2 || uvB.length < 2) {  // sample variance needs n >= 2
            score = "N/A"
            p_value = "N/A (zeros)"
          } else {
            cardinality = Cardinality.Continuous;
            overview = self.describeDifference(field, uvA, uvB);

            // score = simp.tTestTwoSample(uvA, uvB, 0)
            let calc = Stats.tTestInd(uvA, uvB, true);
            score = calc.statistic;  // called statistic here, not score.

            p_value = calc.pValue   //"TBD: pval of " + score.toLocaleString(undefined, { maximumFractionDigits: 5 })
          }
        } else {
          // STRING, so it's Nominal (categorical)
          cardinality = Cardinality.Nominal;
          overview = self.describeDifference(field, uvA, uvB);
          let data = []
          const valsSet = new Set(uvA.concat(uvB));
          let fieldValsSeen = Array.from(valsSet);
          Khonsole.warn("fieldValsSeen...")
          Khonsole.dir(fieldValsSeen)

          let aPercents:number[] = [];
          let bPercents:number[] = [];

          fieldValsSeen.map(fv => {
            let aCount = self.countWordsEqual(uvA, fv);
            let bCount = self.countWordsEqual(uvB, fv);
            let aPerc = aCount / uvA.length;
            let bPerc = bCount / uvB.length;
            aPerc = aPerc * 100;
            bPerc = bPerc * 100;
            aPercents.push(aPerc);
            bPercents.push(bPerc);
            Khonsole.log(`percs for ${field.key}: ${aPerc}, ${bPerc}`)
            let valItem = { mylabel: fv, myvalue: aPerc, myvalue2: bPerc }
            data.push(valItem);
          })
          procCatPercent = data;

          // Use chi-square test for independence.
          // Unless there are <5 items in a bin. In which case maybe use Fisher Exact Test?
          let calc = Stats.chi2Contingency(aPercents, bPercents)
          score = calc["statistic"]
          p_value = calc["pValue"]
          if(isNaN(p_value)){
            Khonsole.warn(`NaN pvalue for ${field.key}`)
            p_value = 1;  // NOTE: to avoid issues
          }
          if (p_value == null) {
            Khonsole.warn(`Null pvalue for ${field.key}`)
            p_value = 0;  // NOTE: to avoid issues
          }
        }

        let item:CohortComparisonDataItem = {
          pos: i,
          category: 'Clinical',
          metric: field.label,
          cardinality: cardinality,
          overview: overview,
          score: score,
          p_value: p_value,
          dataA: uvA,
          dataB: uvB,
          dataCategories: dataCats,
          processedCategoryPercentages: procCatPercent

        }
        self.data.push(item);
        i++;
      })
    } else {
      this.data = [];
    }

    let sort = { active: 'p_value', direction: 'asc' };
    this.sortData(sort)

  }

  describeDifference(field:DataField, uva:number[], uvb:number[]):any {
    if(field.type=="STRING") {
      let numCategories = field.values.length;
      Khonsole.warn("TBD -- prune number of categories in A Union B")
      return `Independence of ${numCategories} categories`
    } else {
      Khonsole.log(`computeDifference for average of ${field.label}`);
      let mfvA = this.getMeanFieldValue(field, this.cohortA)
      let mfvB = this.getMeanFieldValue(field, this.cohortB)


      return `Mean: ${StatFactory.nicelyFormattedNumber(mfvA)} vs. ${StatFactory.nicelyFormattedNumber(mfvB)}`;
    }
  }

  pidsForNotInCohortA(){
    let pids: Array<string> = [];
    if (this.cohortA == null) {
      pids = this.patientData.map(x => x.p);
    } else {
      let allPids = this.patientData.map(x => x.p);
      let pidsOfCohortA = [];
      let _cohortA = this.cohortA;
      if (this.cohorts.length > 0) {
        pidsOfCohortA = this.cohorts.find(c => c.n == _cohortA.n).pids;
      } else {
        Khonsole.warn("pidsForNotInCohortA, cohorts.length == 0")
      }
      pids = allPids.filter(element => pidsOfCohortA.includes(element) == false);
    }
    return pids;
  }

  getUsableValues(field: DataField, cohort: Cohort) {
    let vals:Array<number> = []

    let self = this;
    let pids: Array<string> = [];
    if (cohort.n == "All Patients") {
      pids = this.patientData.map(x => x.p);
    } else {
      if (cohort.n == "Not In Cohort A") {
        pids = this.pidsForNotInCohortA();
      } else {
        pids = cohort.sids.map(function (sid) {
          let pid = OncoData.instance.currentCommonSidePanel.commonSidePanelModel.sampleMap[sid];
          return pid
        })
      }
    }

    pids.map(function (pid) {
      let patient = self.patientData.find(row => row.p == pid);
      if (patient) {
        let val = patient[field.key];
        if (val) {
          vals.push(val);
        }
      }
    })
    return vals;
  }

  getMeanFieldValue(field:DataField, cohort:Cohort){
    let total=0;
    let count=0;
    let self = this;
    let pids:Array<string> = [];
    if(cohort.n=="All Patients"){
      pids = this.patientData.map(x => x.p);
    } else {
      if (cohort.n == "Not In Cohort A") {
        pids = this.pidsForNotInCohortA();
      } else {
        pids = cohort.sids.map(function (sid) {
          let pid = OncoData.instance.currentCommonSidePanel.commonSidePanelModel.sampleMap[sid];
          return pid
        })
      }
    }
    pids.map(function(pid){
      let patient = self.patientData.find(row => row.p == pid);
      if(patient){
        let val = patient[field.key];
        if (val) {
          total = (total + val)
          count++;
        }
      }
    })
    if(count ==0) {
      return "N/A"
    } else {
      return total/count;
    }
  }

  resetForm(){
    Khonsole.warn('TBD: resetForm in cohort comparison');
    this.cd.detectChanges();
  }

  ngAfterViewInit(): void {
    // this.cd.detectChanges();
  }

  onRowClicked(row: CohortComparisonDataItem) {
    Khonsole.log("row click...")
    Khonsole.dir(row)
    this.selectedRow = row;
    this.selectedRowIndex = row.pos;
    let el: HTMLElement = document.getElementById("compare-graph-div")
    if (el) {
      if (row.score == "---" || row.score == "TBD" || row.score.toString().includes(" N/A ") || row.p_value.toString().startsWith("N/A")){
        // clear chart
        el.innerHTML = "<i>Graph not available for that metric.</i>"
      } else {
        // Populate chart
        el.innerHTML = ""

        try {
          let data = []
          if(row.cardinality == Cardinality.Continuous){
            data = [
              { mylabel: this.cohortA.n, myvalue: simp.mean(row.dataA) },
              { mylabel: this.cohortB.n, myvalue: simp.mean(row.dataB) }
            ]
          } else {
            data = row.processedCategoryPercentages;
          }
          let stat:StatOneD  = {
            charts: ['Histogram'],
            columns: 6,
            data  : data,
            name: row.cardinality == Cardinality.Continuous ? `Mean: ${row.metric}` : `Independence of ${row.metric}`,
            renderer: 2,
            type: 1
          }

          let w = 500;
          let h = 300;
          this.drawGoogleSingleStatChart(stat, w, h, row.cardinality)
        } catch (ex) {
          Khonsole.warn("EXCEPTION in simple statistics")
          Khonsole.dir(ex)
          let errMsg = "Check for enough data."
          el.innerHTML = "<i>Graph not available for that metric.<br>ERROR: "+errMsg+"</i>"
        }

      }
    }
  }

  drawSingleStatChart(stat, w, h) {
    Khonsole.warn("SVG create overkill")
    var divForSvgSelection = d3.select("#compare-graph-div");

    this.svg = divForSvgSelection.append("svg");
    this.svg
      .attr("width", 500)
      .attr("height", 300);

    this.statFactory.drawStatChartFromLibrary(stat, w, h, this.svg)
  }

  drawGoogleSingleStatChart(stat, w, h, comparisonType:Cardinality) {
    Khonsole.warn("GoogleSingleStatChart")
    var data = new google.visualization.DataTable();

    const options: google.visualization.ColumnChartOptions = {
      title: stat.name,
      legend: 'none',
      colors: [this.cohortAColor, this.cohortBColor],
      vAxis: {
        title: null,
        viewWindow: {
          min: 0
        },
        format: 'decimal'
      }
    };


    if (comparisonType != Cardinality.Continuous) {
      options.vAxis.format = '#\'%\''
    }

    function drawMultSeries(data) {
      var chart = new google.visualization.ColumnChart( document.getElementById('compare-graph-div'));
      chart.draw(data, options);
    }

    if (comparisonType == Cardinality.Continuous) {
      options.vAxis.title = "Mean";

      data = google.visualization.arrayToDataTable([
        ['Cohort', 'Mean', { role: 'style' } ],
        [stat.data[0].mylabel, stat.data[0].myvalue, this.cohortAColor],
        [stat.data[1].mylabel, stat.data[1].myvalue, this.cohortBColor]
      ]);

    } else {
      let data_inputs = [['Metric', this.cohortA.n, this.cohortB.n]];
      stat.data.map(r => {
        data_inputs.push([r.mylabel, r.myvalue, r.myvalue2])
      });
      Khonsole.log("data_inputs...")
      Khonsole.dir(data_inputs)
      data = google.visualization.arrayToDataTable(data_inputs);


    }
    drawMultSeries(data)

  }


  // naiveDiffExpClick() {
  //   if(this.tables == null || this.tables.length == 0) {
  //     alert('There are no molecular tables in this data set which can be used here for a comparison of the cohorts.');
  //     return;
  //   }

  //   if (this.cohortA == null || this.cohorts.filter(c => c.n == this.cohortA.n).length == 0) {
  //     alert('Please choose a first cohort for the comparison.');
  //   } else {
  //     if (this.cohortB == null || this.cohorts.filter(c => c.n == this.cohortB.n).length == 0) {
  //       alert('Please choose a second cohort for the comparison.');
  //     } else {
  //         Khonsole.log(`Starting clinical feature comparison of "${this.cohortA.n}" and "${this.cohortB.n}".`);

  //     }
  //   }
  // }



  calculateNaiveDiffExp(config: GraphConfig, table:DataTable)  {
    let myComputationResult = null;

    let tableName = table.tbl;
    let self = this;
    let storedMapData;
    self.progressMode = 'buffer';
    self.progressValue = 0;
    let dataNeedsLoading = WorkspaceComponent.instance.hasLoadedTable(tableName) == false;
    if(dataNeedsLoading==false){
      let loadedTable = WorkspaceComponent.instance.getLoadedTable(tableName);
      myComputationResult = DiffexpWidgetComponent.gutsOfNaiveDiffExp(loadedTable.map, loadedTable.data, self.cohortA, self.cohortB);
    } else {
      this.dataService.getTable(config.database, tableName+'Map' ).then(mapResult => {
        Khonsole.log(`Result of then in calculateNaiveDiffExp.`);
        mapResult.toArray().then(mapData => {
          storedMapData = mapData;
          //Khonsole.log(`map data is ${mapData.length} rows.`);
          this.dataService.getTable(config.database, tableName ).then(result => {
            result.toArray().then((expressionData) => {
              let thisLoadedTable:LoadedTable = {
                map: storedMapData,
                data: expressionData
              }
              WorkspaceComponent.instance.setLoadedTable(tableName, thisLoadedTable);

              self.progressMode = 'determinate';
              self.progressValue = 30;
              window.setTimeout(function(){self.cd.detectChanges();}, 50);

              myComputationResult = DiffexpWidgetComponent.gutsOfNaiveDiffExp(storedMapData, expressionData, self.cohortA, self.cohortB);

              self.progressValue = 0;
              self.progressMode = 'determinate';

              self.cd.detectChanges();
              alert('done')
            });
          });
            });
      });
    }
  }

  constructor(
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private dataService: DataService
  ) {
    Khonsole.warn("===COHORT COMPARE constructor")
    this.cohortA = null;
    this.cohortB = null;

    let self = this;
    this.statFactory = StatFactory.getInstance(this.dataService);
    this.patientData = OncoData.instance.currentCommonSidePanel.commonSidePanelModel.patientData;
    this.sampleMap = OncoData.instance.currentCommonSidePanel.commonSidePanelModel.sampleMap;

  }
}
